import React from "react";
import PrismCode from '../PrismCode'

import {Link} from "react-router-dom";

// https://react-icons.github.io/react-icons/icons?name=ai
// import icons individually. 
import { AiOutlineBulb } from "react-icons/ai"; 

const codeBlockOne = `
newButton = QPushButton("Press me") 
newButton.clicked.connect(newButtonIsClicked)
`;

const codeBlockTwo = `
newButton = QPushButton("Press me") 
newButton.clicked.connect(newButtonIsClicked)

def newButtonIsClicked():
    print('button clicked!!')
`;


const finalWorkingCode = `
from PyQt5.QtWidgets import QDialog, QHBoxLayout, QPushButton

def newButtonIsClicked():
    print('button clicked!!')

# add button and layout for button
layoutForButtons = QHBoxLayout()
newButton = QPushButton("Press me") 
layoutForButtons.addWidget(newButton)

# hook up the button to an 
newButton.clicked.connect(newButtonIsClicked)

# create dialog  and show it
newDialog = QDialog() 
newDialog.setLayout(layoutForButtons)
newDialog.setWindowTitle("New Dialog Title!") 
newDialog.exec_() # show the dialog
`;


const finalWorkingCodeTwo = `
from PyQt5.QtWidgets import QDialog, QHBoxLayout, QPushButton

def newButtonIsClicked():
    Krita.instance().action('mirror_canvas').trigger()
    Krita.instance().action('deselect').trigger()

# add button and layout for button
layoutForButtons = QHBoxLayout()
newButton = QPushButton("Press me") 
layoutForButtons.addWidget(newButton)

# hook up the button to an 
newButton.clicked.connect(newButtonIsClicked)

# create dialog  and show it
newDialog = QDialog() 
newDialog.setLayout(layoutForButtons)
newDialog.setWindowTitle("New Dialog Title!") 
newDialog.exec_() # show the dialog
`;

const codeBlockThree = `
@pyqtSlot(int, str)
def newButtonIsClicked(self, arg1, arg2):
    print('button clicked ' + arg2 )
`;



class LessonSignals extends React.Component {

  // note: to use the code, you have to wrap it in   {`code goes here` } so Reeact can render it right
  // this is called ES5 template strings
  render() {
    return (
      <div>

        <p>In the previous lesson we went over how to create a dialog and add a button to it, but we didn't go over how to actually wire it up and make it do stuff. That is what we will go over now learning about a concept called signals and slots.</p>

        <p>When a button is pressed, it sends a 'signal' out that it was pressed. Buttons are always sending these signals so we just need a way for our script to listen to these events. Let's look at a small code snippet. This code snippet won't work if you just paste this in Scripter, but we will get a full working example by the end of this lesson.</p>

        <PrismCode code={codeBlockOne} language="python" />

        <p>QPushButtons has "clicked.connect()" that allows us to listen to click events and do something when a click happens. The "newButtonIsClicked" will be some code that we write next. Again, this next code snippet won't work individually. Just concentrate on understanding the concepts by reading for now.</p>
        
        <PrismCode code={codeBlockTwo} language="python" />

        <p>This introduces a few new things that we haven't seen before. The "def" stands for define. This is how we write a piece of code that only runs when we tell it to. The way we have our code, the newButtonIsClicked will only run when the button is clicked. Other points to look out for is see how there is a ":" character at the end of the define. Also notice the space characters before print(). If you forget either of those, there will be errors. </p>


        <blockquote>
          <p><AiOutlineBulb /> Tip</p>
          <p>When you want to run a function, you do not need to put the <strong>()</strong> symbols by it. You only need to do that when you are defining a function. </p>
        </blockquote>

        <h3>Fully working example</h3>

        <p>Enough with small code snippets. Let's see a full working example with a dialog that has a button.</p>

        <PrismCode code={finalWorkingCode} language="python" />

        <p>If you copy this all and run it in Scripter you should get a dialog that has a button. When you click the button, you will see the message "button clicked" in the Scripter output. Good job</p>

        <p>From this simple working example, we can start modifying it and start using actions to do things with the button click. Try this example out</p>

        <PrismCode code={finalWorkingCodeTwo} language="python" />

        <p>Can you see what it is doing? When the button is pressed, Krita is mirroring the canvas, then removing any selection with the "deselect". Is this a very useful? Probably not, but hopefully you can see how you can plug in actions, or other scripting when a button is clicked.</p>

        <p>An important thing to note is WHERE we put the def newButtonIsClicked(). Notice how it is at the beginning before most of the code. If you try to move that to the end of the script and run it, you will get an error. Why?</p>

        <p>The reason that def newButtonIsClicked needs to be at the beginning is that it needs to exist before it is used. In line 12, that is where the funcition is being called. Scripts are processed from top to bottom. If Scripter is running this code and it hits a "newButtonIsClicked", it won't know about it if it is defined at the bottom. These "undefined" errors are pretty common in scripting, so just be aware that the order that code is written is very important.</p>

        <h3>What are slots?</h3>

        <p>We didn't cover what a "slot" is, but we are already using one in our examples. A slot is the function that <em>listens</em> to a signal. The click event is the <em>signal</em>, and the function we hooked it up to is the <em>slot</em>. The slot was the newButtonIsClicked in our example.  The way our code looks is one way to create a signal and a slot. There are other ways to do the same thing, but we can go over other ways in a future lesson. </p>


        <h4>Another way to see slots</h4>

        <p>If you start looking through the python code in plugins, you will notice that some of them have another format for slots that are used like this.</p>

        <PrismCode code={codeBlockThree} language="python" />

        <p>There is an odd @ symbol with pyqtSlot added before the function. What this is doing is being a bit more descriptive on how the slot is being handled. Sometimes we need to pass certain types of data like text or numbers. This helps the function realize what type of data is being used. The technical name for this @ symbol thing is called a "decorator". All decorators like this provide some additional information about how a function will work. We don't need to get in-depth with it now, but know they still have the same purpose as the other slots we made earlier. </p>


        <h3>Conclusion</h3>

        <p>We have learned in this lesson how UI components like buttons send signals to our script. We learned how to respond to these button clicks by triggering actions. Next we will learn how to create items off the main menu.</p>



      </div>
    );
  }

}

export default LessonSignals;



import React from "react";
import PrismCode from '../PrismCode'
import {Link} from "react-router-dom";


// https://react-icons.github.io/react-icons/icons?name=ai
// import icons individually. 
import { AiOutlineBulb } from "react-icons/ai"; 


const desktopFile = `
[Desktop Entry]
Type=Service
ServiceTypes=Krita/PythonPlugin
X-KDE-Library=docker_template
X-Python-2-Compatible=false
X-Krita-Manual=Manual.html
Name=docker_template
Comment=Getting a docker set up
`;

const initFile = `
from krita import DockWidgetFactory, DockWidgetFactoryBase
from .docker_template import DockerTemplate

DOCKER_ID = 'template_docker'
instance = Krita.instance()
dock_widget_factory = DockWidgetFactory(DOCKER_ID,
                                        DockWidgetFactoryBase.DockRight,
                                        DockerTemplate)

instance.addDockWidgetFactory(dock_widget_factory)
`;


const dockerFile = `
from krita import DockWidget

DOCKER_TITLE = 'Blank Template Docker'

class DockerTemplate(DockWidget):

    def __init__(self):
        super().__init__()
        self.setWindowTitle(DOCKER_TITLE)

    # notifies when views are added or removed
    # 'pass' means do not do anything
    def canvasChanged(self, canvas):
        pass
`;


const kritarcPlugin = `
[python]
enable_docker_template=true
`;



class LessonCreateSimplePlugin extends React.Component {

  // note: to use the code, you have to wrap it in   {`code goes here` } so Reeact can render it right
  // this is called ES5 template strings
  render() {
    return (
      <div>
        
      <h2>Starting with a docker template</h2>

      <p>To make things easier we are going to start with a blank docker template that has the skeleton all set up.</p>

      <a href="/images/lessons/plugins-create/docker_template.zip">Download blank docker template ZIP</a><br/><br/>

      <p>After you download the zip, unzip the contents in the local pykrita folder. This is where Krita store all the local plugins.</p>

      <ul>
        <li>Windows (paste this in file browser) - <strong>%APPDATA%\krita\pykrita</strong> </li>
        <li>Linux<strong> ~/.local/share/krita/pykrita</strong> </li>
        <li>Apple<strong> ~/Library/Application Support/Krita/pykrita</strong> </li>
      </ul>

      <h3>Analyzing the anatomy of a plugin</h3>

        <p>Let's look at the files the plugin created. Krita stores its plugins in a local directory on your computer. All the plugins are stored in the pykrita folder. When Krita starts, it looks in this folder for plugins to load. If you have ever used Krita as an artist and imported a plugin, this is the location where it loaded the files to. Copy the ZIP files to the location so it looks like this...</p>

       <img src="/images/lessons/plugins-create/plugin-create-1.png" alt="Plugin directory" />

       
       <blockquote>
          <p><AiOutlineBulb /> Tip</p>
          <p>You will only see custom plugins stored in your pykrita folder. All of the plugins that come pre-installed with Krita are inside the folder where Krita was installed.</p>
        </blockquote>


        <p>When we first go into the pykrita folder, you will see a .desktop file and a folder with your plugin name. The .desktop file is how Krita first reads the information about the plugin. If you have other custom plugins installed, they will also have a folder and a desktop file like this. </p>

       <h4>Understand .desktop</h4>
        <p>The .desktop file is just a text file. Open it up in a text editor and examine it. There are only three fields you have to really care about.</p>

        <PrismCode code={desktopFile} language="python" />

        <ul>
          <li>X-KDE-Library - Has the same name as the folder for the plugin.</li>
          <li>Name - What title appears in the python plugin manager.</li>
          <li>Comment - What description appears in the python plugin manager.</li>
        </ul>

        <h4>Understand __init__.py file</h4>

        <p>If we look into the plugin folder, we will see a few files. The two PY files are the ones that do most of the work. Let's take a look at each one of those.</p>

        <img src="/images/lessons/plugins-create/plugin-create-2.png" alt="inside plugin directory 2" />

        <p>The <strong>__init__</strong> file is what is read first. All plugins will have this file and it is always called __init__.py. Let's open that up first and see what is going on. </p>

        <PrismCode code={initFile} language="python" />

        <p>The first import is bringing in some classes to help our plugin work as a docker. The second import is saying that there is another file that we are going to use that has the filename "docker_template". The DockerTemplate is the class name we are going to use later.</p>

        <p>The next few lines of code is how we setup a docker. This isn't something you really customize that much, so just know that this code is what makes our plugin a docker. The other important thing is the "DockerTemplate". This is the file that is getting used to figure out what our docker is going to do. Let's look at that next. </p>

        <h4>Plugin file</h4>

        <PrismCode code={dockerFile} language="python" />

        <p>There isn't really much going on with this file since it is a shell. The __init__(self) is the function that runs when the plugin first starts. The other canvasChanged(self, canvas) function is called every time views are added or removed. You cannot remove this function or there will be errors.</p>


        <h3>Enabling plugins</h3>

        <p>The last part with how plugins are structured is how they are enabled. When you first create, or copy, a plugin to the pykrita folder, the plugin will be disabled by default. You can enable the plugin from the python manager in Krita. Where is this enabled state stored? That is saved in the kritarc configuration file. When you enable or disable a plugin, the kritarc file gets updated like this.</p>

        <PrismCode code={kritarcPlugin} language="python" / >

        <p>With this setting saved, Krita knows what it needs to load when it starts up.</p>

        <blockquote>
          <p><AiOutlineBulb /> Tip</p>
          <p>The kritarc file is where all application settings are saved in Krita. kritarc is just a text file, so you can open it in any text editor. The "rc" at the end of this file stands for "run command".</p>
        </blockquote>

        <h3>Errors when loading plugins</h3>

        <p>When you start developing plugins you will almost certainly run into issues at some point. If you start Krita and your plugin isn't doing anything check the python plugin manager. Look for your plugin and see if it is greyed out. If it is, that means there was some issue loading it</p>

        <img src="/images/lessons/plugins-create/plugin-error.gif" alt="Finding Python errors on load" />

        <p>If you hover over your disabled plugin, Krita will give you a message with the error and what is going on. Sometimes loading plugins will give you the error message when you load Krita. Other times you will need to check the python plugin manager to see why it is disabled. If your plugin is enabled and still not working, that means there is something wrong with your logic where it isn't being done correctly. The only way to figure that out is to analyze the code, add print statements, or re-arrange things to understand why the code isn't working </p>

      </div>
    );
  }

}

export default LessonCreateSimplePlugin;



import React from "react";
import PrismCode from '../PrismCode'

import {Link} from "react-router-dom";

const pyQtVersionCode = `
from PyQt5.Qt import PYQT_VERSION_STR
print("PyQt version:", PYQT_VERSION_STR)
`;



class LessonApiKritaLib  extends React.Component {

  render() {
    return (
      <div>
        <h2>Krita's own API</h2>

        <p>For granular control using Krita, it has its own API for doing various actions. Probably the biggest thing that can be confusing is knowing what uses the Krita API, and what is using another API like PyQt5.</p>

        <p>Probably the best way to look around is to check out the <a href="https://api.kde.org/krita/html/classKrita.html" target="_blank" rel="noopener noreferrer">documentation for the Krita API</a>.</p> 
        
        <img src="/images/lessons/api-krita/krita-api.png" alt="Krita's API" />
        
        
        <p>It is quite a bit smaller than PyQt, so it shouldn't take that long to look around to get a feel for the types of things you can do. It is also easy to forget that many things can be done using actions, so make sure to check the <Link to="/action-dictionary">Action Dictionary</Link>  out if you cannot find something in the Krita API.</p>


      </div>
    );
  }

}

export default LessonApiKritaLib;



import React, {Component} from "react"
import Prism from "prismjs"
require('prismjs/components/prism-python');
require('prismjs/plugins/line-numbers/prism-line-numbers');
require('prismjs/plugins/show-invisibles/prism-show-invisibles');

require('prismjs/plugins/toolbar/prism-toolbar'); // need this for copy to clipboard to work
require('prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard');




/* this class helps wrap all the Prism JS hooks and functionality into
one plugin so there is more centralized code between pages */
class PrismCode extends Component {
  
    constructor(props) {
    super(props)
    this.ref = React.createRef()
  }
  
  componentDidMount() {
    this.highlight();
  }

  componentDidUpdate() {   
    this.highlight();
    console.log(Prism);    
  }

  highlight = () => {
    if (this.ref && this.ref.current) {
      Prism.highlightElement(this.ref.current);
    }
  }

  render() {
    // attributes we will define when calling component
    // calling out componenets like this is called "de-structuring"
    const { code, language } = this.props 

    return (
      <pre className="line-numbers">
        <code ref={this.ref} className={`language-${language}`} data-prismjs-copy="Copy">
          {code.trim()}
        </code>
      </pre>
    )
  }
}


export default PrismCode;
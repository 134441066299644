import React from "react";
import PrismCode from '../PrismCode'

import {Link} from "react-router-dom";

const codeBlockOne = `
application = Krita.instance()
currentDoc = application.activeDocument()

# get the current selected layer, called a 'node'
currentLayer = currentDoc.activeNode()

# gets layers that are not nested
# you will need to loop through them if you want all layers
nodesList = currentDoc.topLevelNodes()

# find a node by name
# this will also search child/nested layers
layerFoundByName = currentDoc.nodeByName("Paint 2")
`;


const codeBlockTwo = `
application = Krita.instance()
currentDoc = application.activeDocument()

# gets layers that are not nested
# you will need to loop through them if you want all layers
topLevelLayers = currentDoc.topLevelNodes()

for layer in topLevelLayers:
  layer.setLocked(True)
`;


const codeBlockThree = `
application = Krita.instance()
currentDoc = application.activeDocument()

# grab all top level nodes
topLevelLayers = currentDoc.topLevelNodes()

for layer in topLevelLayers:
  layer.setLocked(True)
  
  # grab the layer children to see if we need to set those
  layerChildren = layer.childNodes() 
    
  if len( layerChildren ) > 0:
      # we have children, so loop through those
      for child in layerChildren:
          child.setLocked(True)
`;


const codeBlockFour = `
application = Krita.instance()

# a few avaialable actions you could do
application.action('merge_layer').trigger()

application.action('duplicatelayer').trigger()

application.action('copy_layer_clipboard').trigger()

application.action('move_layer_up').trigger()

`;

const codeBlockFive = `
application = Krita.instance()
currentDoc = application.activeDocument()

# find a node by name
layerFoundByName = currentDoc.nodeByName("Paint 2")

# change our active layer selection to the layer we found
currentDoc.setActiveNode(layerFoundByName)

# now all our layer actions will affect Paint 2
application.action('duplicatelayer').trigger()
`;



const codeBlockSix = `
application = Krita.instance()
currentDoc = application.activeDocument()

# find a node by name
layerFoundByName = currentDoc.nodeByName("Paint 2")

# change our active layer selection to the layer we found
currentDoc.setActiveNode(layerFoundByName)

# Delete this layer
application.action('remove_layer').trigger()
`;


const codeBlockChangeOpacity = `
# create new document createDocument(width, height, name, colorSpace, bitDepth, colorProfile, DPI)
newDocument = Krita.instance().createDocument(1280, 1024, "Document name", "RGBA", "U8", "", 300.0)
Krita.instance().activeWindow().addView(newDocument) # shows it in the application

# do things with a layer
currentLayer = newDocument.activeNode()
currentLayer.setOpacity(128) # 0-255 translates to 0-100%
newDocument.refreshProjection() # redraw the canvas on the screen with the new opacity change
`;



class LessonLayers extends React.Component {

  // note: to use the code, you have to wrap it in   {`code goes here` } so Reeact can render it right
  // this is called ES5 template strings
  render() {
    return (
      <div>

        <p>The scripting API for layers allows you to create, edit, and delete layers. Most of the commands are accessible with either using actions or through the Krita Node API.</p>


        <h3>Getting access to layers</h3>

        <p>There are a few different ways to access layers.</p>


        <PrismCode code={codeBlockOne} language="python" />

        <p>Another common scenario is going through all layers and doing stuff. This is a simple use case of going through all layers and locking them.</p>

        <PrismCode code={codeBlockTwo} language="python" />

        <p>While this code will work great if we only have one level, it will not go into group layers and update those children. We will need to just add a bit more code to go inside those and do a similar thing</p>

        <PrismCode code={codeBlockThree} language="python" />

        <p>This is just doing more loops inside of loops looking through children. This will go one level deep. Of course there could be two, three, or more levels deep of children. We won't go into all the ways this could work, but you would have to create a recursive function that would keep going deeper as long as there are more children.</p>

        <h3>Doing actions on layers</h3>

        <p>Now that you know a few ways to access layers, you probably want to do things with them. For simple operations, there are many things you can do with simply using actions</p>


        <PrismCode code={codeBlockFour} language="python" />

        <p>When you use actions like above,  It is important to know that the action is done ONLY on the currently selected layer. When you grab a layer, you first need to select it as the active layer. Then you can perform the actions. Like this. </p>


        <PrismCode code={codeBlockFive} language="python" />

        <h3>Changing properties on layers</h3>

        <p>We have the ability to access and change a lot of the layer's properties with more precision. In this next example, we are going to create a document, then change the opacity of the current layer.</p>

        <PrismCode code={codeBlockChangeOpacity} language="python" />

        <p>After the document is created, we grab active layer, called a “node” and change the opacity. Notice the last line with “refreshProjection()”. If that line is not there, you would not see your canvas updated on screen.  The reason this exists is you might want to do 5-10 things with the canvas. It is much faster if Krita can do everything first before it spends time drawing all those pixels on the canvas.</p>  

        <p>There are a lot of properties you can change from the <a target="_blank" rel="noopener noreferrer" href="https://api.kde.org/krita/html/classNode.html">Krita Node API</a>. See this API reference to see all the available things you can do with layers/nodes. </p>



        <h3>Removing layers and performing more actions</h3>


        <p>We went over how to grab and perform actions on layers. Deleting a layer is just another action. This is how you would find a layer you want to delete, then delete it.</p>

        <PrismCode code={codeBlockSix} language="python" />


        <p>There are a lot of actions that can be done with layers. To see a list of them all, head over to the <Link to="/action-dictionary">action dictionary page</Link>  and do a search for "layer". use the id for the action name in your scripting. </p>


      </div>
    );
  }

}

export default LessonLayers;



// import actual components here
import LessonIntroduction from './LessonIntroduction'
import LessonTalkingWithKrita from './LessonTalkingWithKrita'
import LessonActions from './LessonActions'
import LessonCreateSimplePlugin from './LessonCreateSimplePlugin'
import BeginnerUI from './LessonsBeginnerUI'

import LessonSignals from './LessonSignals'
import LessonMenus from './LessonMenus'
import LessonLayers from './LessonLayers'
import LessonNotifiers from './LessonNotifiers'
import LessonFilters from './LessonFilters'
import LessonAnimation from './LessonAnimation'
import LessonDockers from './LessonDockers'
import LessonResources from './LessonResources'
import LessonSelections from './LessonSelections'
import LessonImageData from './LessonImageData'

import LessonPluginsIntro from './LessonPluginsIntro'
import LessonPluginsLearnFromReference from './LessonPluginsLearnFromReference'
import LessonExtensions from './LessonsExtensions'
import LessonAddingPluginToKrita from './LessonAddingPluginToKrita'

import LessonApiPyQt from './LessonApiPyQt'
import LessonApiKritaLib from './LessonApiKritaLib'


// specify the data for each lesson. You can literally add a commponent object to the JSON data
const LessonData = [
    {
        "id": 'introduction',
        "level": 'beginner',
        "name": "Scripting with Krita",
        "overview": "Overview of scripting in Krita. Go over the Scripter tool for writing and executing basic scripts",
        "component": LessonIntroduction
    },
    {
        "id": 'documents',
        "level": 'beginner',
        "name": "Documents",
        "overview": "See how you can access and control Krita through scripting",
        "component": LessonTalkingWithKrita
    },
    {
        "id": 'actions',
        "level": 'beginner',
        "name": "Actions",
        "overview": "Learn how to perform common operations that Krita uses with simple commands",
        "component": LessonActions
    },
    {
        "id": 'beginner-ui',
        "level": 'beginner',
        "name": "Dialogs and buttons",
        "overview": "Start creating a user interface for your scripting by putting a button in a dialog",
        "component": BeginnerUI
    }, 
    {
        "id": 'signals',
        "level": 'beginner',
        "name": "Signals and slots",
        "overview": "Allow scripts to run only after a button is clicked",
        "component": LessonSignals
    },
    {
        "id": 'layers',
        "level": 'beginner',
        "name": "Layers",
        "overview": "Work with layers by adding, modifying, and deleting",
        "component": LessonLayers
    },  
    {
        "id": 'selections',
        "level": 'beginner',
        "name": "Selections",
        "overview": "Have more control with your images by selecting specific parts to affect.",
        "component": LessonSelections  
    }, 
    {
        "id": 'notifiers',
        "level": 'beginner',
        "name": "Notifiers",
        "overview": "Listen and respond to events that happen in Krita",
        "component": LessonNotifiers  
    }, 
    {
        "id": 'menus',
        "level": 'beginner',
        "name": "Menus",
        "overview": "Create new main menu items and add menu options to buttons",
        "component": LessonMenus  
    }, 
    {
        "id": 'filters',
        "level": 'beginner',
        "name": "Filters",
        "overview": "See what filters can be used and apply them to your image",
        "component": LessonFilters
    },
    {
        "id": 'docker-widgets',
        "level": 'beginner',
        "name": "Dockers",
        "overview": "Access and control the dockers that come with Krita",
        "component": LessonDockers
    },
    {
        "id": 'animation',
        "level": 'beginner',
        "name": "Animation",
        "overview": "Manipulate the animation timeline and use controls for playback",
        "component": LessonAnimation
    },
    {
        "id": 'resources',
        "level": 'beginner',
        "name": "Resources",
        "overview": "Learn how to work with resources like patterns, brushes, and gradients",
        "component": LessonResources
    },
    {
        "id": 'image-data',
        "level": 'beginner',
        "name": "Image data",
        "overview": "Retrieving and using image data",
        "component": LessonImageData
    },    
    {
        "id": 'plugins-introduction',
        "level": 'intermediate',
        "name": "Plugin Introduction",
        "overview": "Discuss what plugins are, pros and cons of them, why we need them, and the types of plugins we can use",
        "component": LessonPluginsIntro  
    }, 
    {
        "id": 'plugins-create',
        "level": 'intermediate',
        "name": "Anatomy of a plugin",
        "overview": "Analyze a plugin and see how Krita reads and uses them",
        "component": LessonCreateSimplePlugin
    },
    {
        "id": 'plugins-extensions',
        "level": 'intermediate',
        "name": "Extensions",
        "overview": "Build other functionality into your plugin that is not a docker",
        "component": LessonExtensions
    },
    {
        "id": 'plugins-learn-from-reference',
        "level": 'intermediate',
        "name": "Learning from other plugins",
        "overview": "See where Krita keeps its plugins and where community plugins are kept",
        "component": LessonPluginsLearnFromReference
    },
    {
        "id": 'plugin-submission',
        "level": 'intermediate',
        "name": "Submitting plugins",
        "overview": "Rules and guidelines when submitting your plugins to be included as part of Krita",
        "component": LessonAddingPluginToKrita
    },
    {
        "id": 'reference-api-pyqt',
        "level": 'reference',
        "name": "PyQt5 API",
        "overview": "Learn how to read through the Qt API and translate code form C++ to python",
        "component": LessonApiPyQt
    },
    {
        "id": 'reference-api-krita',
        "level": 'reference',
        "name": "Krita API",
        "overview": "Learn how to read through the Krita API",
        "component": LessonApiKritaLib
    }




    
    
];

export default LessonData;

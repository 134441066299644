import React from "react";
import PrismCode from '../PrismCode'

import {Link} from "react-router-dom";

const codeBlockOne = `
from PyQt5.QtWidgets import QDialog

newDialog = QDialog() # create dialog and assign it to a variable
newDialog.setWindowTitle("New Dialog Title!") 
newDialog.exec_() # show the dialog
`;

const codeBlockTwo = `
from PyQt5.QtWidgets import QDialog, QHBoxLayout, QPushButton

# add button and layout for button
layoutForButtons = QHBoxLayout()
newButton = QPushButton("Press me") 
layoutForButtons.addWidget(newButton)

# create dialog  and show it
newDialog = QDialog() 
newDialog.setLayout(layoutForButtons)
newDialog.setWindowTitle("New Dialog Title!") 
newDialog.exec_() # show the dialog
`;


const codeBlockThree = `
from PyQt5.QtWidgets import QDialog, QHBoxLayout, QPushButton, QCheckBox

# add button and layout for button
layoutForButtons = QHBoxLayout()
newButton = QPushButton("Press me") 
layoutForButtons.addWidget(newButton)

#add a checkbox
newCheckbox = QCheckBox()
newCheckbox.setText('I am a checkbox')
layoutForButtons.addWidget(newCheckbox)

# create dialog  and show it
newDialog = QDialog() 
newDialog.setLayout(layoutForButtons)
newDialog.setWindowTitle("New Dialog Title!") 
newDialog.exec_() # show the dialog
`;

const codeBlockIcon = `
from PyQt5.QtWidgets import QDialog, QHBoxLayout, QPushButton

# add button and layout for button
layoutForButtons = QHBoxLayout()
newButton = QPushButton() 
newButton.setIcon(  Krita.instance().icon('animation_play')   )
#newButton.setText( "Press me" )
layoutForButtons.addWidget(newButton)

# create dialog  and show it
newDialog = QDialog() 
newDialog.setLayout(layoutForButtons)
newDialog.exec_() # show the dialog
`;




class BeginnerUI extends React.Component {

  // note: to use the code, you have to wrap it in   {`code goes here` } so Reeact can render it right
  // this is called ES5 template strings
  render() {
    return (
      <div>

      <h2>Creating dialogs and using imports</h2>

      <p>Now that you know how to call a lot of actions in Krita, you might want to take your scripting to the user interface. This will make it easier to call functions from buttons instead of having to manually run a script in Scripter.  In this lesson we will learn how to start creating a user interface and add buttons. We will also go over a bit about how to configure them with icons, and put them in various layouts to change their arrangement.</p>

      <p>What does it take to create a dialog? Something as simple as this script</p>

      <PrismCode code={codeBlockOne} language="python" />

      <p>There are a few things that we haven't seen before. The first line  is called an "import". User interface elements like dialogs and buttons exist in a library called "PyQt". Because they exist in an outside library, we need to tell our script that we are going to use outside things. If we do not import this, the next line will give an error because the script won't know what a QDialog() component is. We will be importing more things as we need them. Exactly when to use them can be a bit tricky, so stick with me and we will get it figured out as the lessons progress.</p>

      <p>The next few lines create a dialog, set the window title, and tell it to open. If you run this code a dialog will appear. It will already have things like a close button at the top right since that is something all dialogs have.</p>

      <h3>Adding a layout and button</h3>

      <p>We have an empty dialog. Now we just need to start adding stuff to it. Let's add a button. It is going to be a bit more code than you might expect, but we will get into that next.</p>

      <PrismCode code={codeBlockTwo} language="python" />

      <p>On the first line you will see that we are importing more UI components. We have a QHBoxLayout component and a QPushButton component. They all come from the same library, so we can just add them all using commas.</p>

      <p>Before you can add buttons, you have to use a layout for the dialog. A layout determines how buttons will be ordered and shown as they are added. In this example we are using a QHBoxLayout. As we add more buttons, the buttons will be added from left to right. Line 6 is where we are adding the button to the layout. On line 10, you will see that the dialog itself has its layout set.</p>

      <p>This separation of layout, dialogs, and buttons is confusing when you are starting off. I like to think of it like this when I am coding something:</p>

      <ol>
        <li>Create a layout first with how you want UI components to flow</li>
        <li>Create the UI components like buttons</li>
        <li>Add the UI components to the layout</li>
        <li>Add the layout to the dialog</li>
      </ol>



      <h3>Adding icons to buttons</h3>

      <p>We went over how to create buttons. Until now the buttons have just had text in them. We also have the ability to easily add icons instead. This is quite easy to do. Krita comes with a lot of icons that you can re-use. The easiest way to get a list of all the icons available is to check out the <Link to="/icon-library">icon library</Link>. You can browse what is available, or search for specific things. The text below the icon names are the IDs. Here is an example of how to hook it up.  </p>

      <PrismCode code={codeBlockIcon} language="python" />




      <h3>Adding a checkbox to our layout</h3>

      <p>Let's finish up by adding one more component. We will be able to see the effect the layout has with this.</p>

      <PrismCode code={codeBlockThree} language="python" />

      <p>With this you will see the added checkbox appear to the right. If we were using a vertical layout with the VBoxLayout, the checkbox would appear below.</p>
      

      <p>As you do more complex user interfaces, you sometimes have to put layouts inside of other layouts to get the result you want. There are some other ways we can create layouts and add buttons that wll simplify this, but we will save that for a more advanced lesson. If you understand the basic concept of layout and adding widgets to layouts, learning how to add more buttons and layouts will be easier.</p>

      <h3>Conclusion</h3>
      
      <p>In this lesson, we went over how to create a dialog, understand what layouts are, and add our first button. Next we will go over how to hook up our button so it can perform actions or other code.</p>


      </div>
    );
  }

}

export default BeginnerUI;



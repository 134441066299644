import React from "react";
import PrismCode from './PrismCode'

const codeBlockOne = `
onSubmit(e) {
	e.preventDefault();
	const job = {
		title: 'Developer',
		company: 'Facebook' 
	};
}
`;


class Home extends React.Component {

  // note: to use the code, you have to wrap it in   {`code goes here` } so Reeact can render it right
  // this is called ES5 template strings
  render() {
    return (
      <div>
        <h1>Home</h1>
        <p>Overview goes here of what this website will be about</p>
		<PrismCode code={codeBlockOne} language="javascript" />
      </div>
    );
  }

}

export default Home;



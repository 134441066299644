import React from "react";
import PrismCode from '../PrismCode'

// https://react-icons.github.io/react-icons/icons?name=ai
// import icons individually. 
import { AiOutlineBulb } from "react-icons/ai"; 

const codeBlockOne = `
from krita import *

#create document to work with
newDocument = Krita.instance().createDocument(800, 600, "Document name", "RGBA", "U8", "", 300.0)
Krita.instance().activeWindow().addView(newDocument) # shows it in the application

# make active node layer opacity 100% 
activeNode = newDocument.activeNode()
activeNode.setOpacity(255)
newDocument.refreshProjection() #update canvas on screen

# create selection
newSelection = Selection()

# configure selection and actually set it
# select(x, y, width, height, howMuchSelected)
newSelection.select(100, 100, 400, 100, 255) #255 is totally selected

# applies the selection to the canvas
newDocument.setSelection(newSelection)
`;


const codeBlockTwo = `
from krita import *

#create document to work with
newDocument = Krita.instance().createDocument(800, 600, "Document name", "RGBA", "U8", "", 300.0)
Krita.instance().activeWindow().addView(newDocument) # shows it in the application


# make active node layer opacity 100% 
activeNode = newDocument.activeNode()
activeNode.setOpacity(255)
newDocument.refreshProjection() #update canvas on screen

# create selection
newSelection = Selection()

# configure selection and actually set it
# select(x, y, width, height, howMuchSelected)
newSelection.select(100, 100, 400, 100, 255) #255 is totally selected
newDocument.setSelection(newSelection)


# Move selection - x and y are relative positions
newSelection.move(100, 0)

# de-select
newSelection.clear()

# invert
newSelection.invert()
`;


const codeBlockThree = `
from krita import *

#create document to work with
newDocument = Krita.instance().createDocument(1280, 1024, "Document name", "RGBA", "U8", "", 300.0)
Krita.instance().activeWindow().addView(newDocument) # shows it in the application

# make active node layer opacity 100% 
activeNode = newDocument.activeNode()
activeNode.setOpacity(255)

# create selection and fill with foreground color
newSelection = Selection()
newSelection.select(100, 100, 400, 100, 255) #255 is totally selected
newDocument.setSelection(newSelection)
Krita.instance().action('fill_selection_foreground_color').trigger()

# copy the selection to clipboard
# refreshProjection() updates canvas so it gets correct pixels to copy
newDocument.refreshProjection() 
newSelection.copy(activeNode)

# paste with action. This automatically creates a new layer for us
Krita.instance().action('edit_paste').trigger()
newDocument.waitForDone () # action needs to finish before continuing
activeNode = newDocument.activeNode()

# continue doing stuff if we want to
activeNode.setName('New set layer name')
`;


class LessonSelections extends React.Component {

  render() {
    return (
      <div>

        <h2>Adding basic selections</h2>

        <p>Some operations you only want to do on specific parts of an image. Whether it is copying it to a new layer, or performing a filter. The selections API allows you to have control over the selections you make. The following example adds a rectangle selection.</p>

        <PrismCode code={codeBlockOne} language="python" />

        <h3>Modifying and removing selections</h3>

        <p>You can also start moving and doing other operations to selections once you have one. Here is a script that is using a bit of the Selections API to modify and remove the existing one.</p>

        <PrismCode code={codeBlockTwo} language="python" />

        <p>One thing that can be confusing is that many of these operations are also available with actions. If you look through the action dictionary, you will see you could also use that. There are certain situations where using actions can be easier to work with.</p>

        <h3>Copying and pasting selections</h3>

        <p>One area that can cause confusion is copying and pasting selections. The actual paste API call is a bit buggy, so it is a bit easier to just use the action for this situation. Here is an example of how to set that up. </p>

        <PrismCode code={codeBlockThree} language="python" />

        <p>When we call the edit_paste action, notice how there is a waitForDone() method. With actions and some image operations, that needs to be called, otherwise you might get inconsistent results. With the edit_paste action, it does a few things like create a new layer, paste the selection to the new layer, and updates the current selected layer. We want all that to be finished before we continue.</p>






<p>If you want to read more about the various things you can do, check out the <a href="https://api.kde.org/krita/html/classSelection.html" target="_blank" rel="noreferrer noopener">Selections API page</a> that lists all the methods that are available.</p>

      </div>
    );
  }

}

export default LessonSelections;



import React from "react";
import {Link} from "react-router-dom";

// https://react-icons.github.io/react-icons/icons?name=ai
// import icons individually. 
import { AiOutlineBulb } from "react-icons/ai"; 

class LessonPluginsLearnFromReference extends React.Component {

  // note: to use the code, you have to wrap it in   {`code goes here` } so Reeact can render it right
  // this is called ES5 template strings
  render() {
    return (
      <div>

    <h2>Where to find the default plugins</h2>

    <p>Krita comes installed with a number of plugins. These were not just written to add functionality, but to also provide a great reference for people to study the python scripts for their own education. All the reference scripts included are plugins, so be sure to understand how plugins work. The first important thing to know is where to find all these plugins to learn from.</p>

    <p>The plugins are all stored wherever you installed Krita. If you have a portable version of Krita, it will be in the portable folder that you are using of it. If you go into that directory, look in the <strong>Share &gt; Krita &gt; pykrita </strong> folder.</p>

    <p>If you are wanting to add something to your plugin, take a look and see if any of the default plugins are doing something similar. Some plugins are simple with only one or two files. Other plugins are more complex with folders of files. Some of the plugin authors try to also write comments explaining more of the complex things they are doing. </p>

    <h3>Where to find the outside plugins</h3>

      <p>Another good place to see what scripts and plugins people are making is on the resources section of docs.krita.org. This contains a collection of scripts and plugins that the community have created on the internet. While you don't want to just copy everything and claim it as your own code, taking a peek and learning how they do something isn't very harmful. </p>


    {/* <blockquote>
          <p><AiOutlineBulb /> Tip</p>
          <p>If your plugin isn't loading, check the python plugin area in the configuration. If the plugin is greyed out, that means there is some coding error happening. If you hover your mouse over the plugin, a tooltip will appear telling you where the error is happening.</p>
    </blockquote> */}


      </div>
    );
  }

}

export default LessonPluginsLearnFromReference;



import React from "react";
import PrismCode from '../PrismCode'

import {Link} from "react-router-dom";

const codeBlockOne = `
from PyQt5.QtWidgets import QDialog, QLabel, QVBoxLayout
`;


class LessonAddingPluginToKrita extends React.Component {

  // note: to use the code, you have to wrap it in   {`code goes here` } so Reeact can render it right
  // this is called ES5 template strings
  render() {
    return (
      <div>
        <h2>Overview</h2>

        <p>Krita includes python scripts that extend its functionality. This page has information for developers who want to have their plugin bundled with Krita. You must be aware that your plugin will be included in the official Krita distribution. Therefore, you must ensure there is continuous support for keeping your plugin up to date. </p>

        <h3>Contact</h3>

       <p>If you have any questions about your plugin or need more information, you can post them on the <a href="https://krita-artists.org/c/develop/plugins-development/16" target="_blank" rel="noopener noreferrer" >plugins category on Krita Artists</a>. </p>

       <h3>Repository</h3>

       <p>Krita uses GIT for source control. The plugins for Krita are stored on KDE's gitlab instance. They are stored in the <a href="https://invent.kde.org/graphics/krita/-/tree/master/plugins/python" target="_blank" rel="noopener noreferrer">python folder</a> under plugins.</p>

       <h3>Licensing</h3>

       <p>If you distribute a Krita plugin it also has to be shared under the GNU GPL. You can charge for the download, but you cannot prevent others from distributing your plugin: your customers will have all the rights guaranteed by the GNU GPL. For more information on licensing, visit krita.org where it goes over it in more detail.</p>


       <h3>Submitting Plugins</h3>

       <p>Follow the guidelines for plugins when implementing one. Submit the plugin via invent.kde.org through a merge request. You will need to make a fork of the Krita repository and include your changes. See the <a href="https://docs.krita.org/en/untranslatable_pages/intro_hacking_krita.html?highlight=hacking#forking-on-gitlab" target="_blank" rel="noopener noreferrer">documentation on creating your fork</a> if you need help. </p>

       <p>The reviewers will decide if the plugin can be added to the official release. It is also a good idea to get feedback about your plugin by sharing it on <a href="https://krita-artists.org/" target="_blank" rel="noopener noreferrer">Krita Artists</a>. This feedback can help in the decision on whether to include it or not. </p>


      <h3>Bug Reports</h3>

      <p>Bugs for all plugins are reported to the plugin project. If a bug is filed for your add-on, the bug will be assigned to you as the plugin maintainer.</p>




      </div>
    );
  }

}

export default LessonAddingPluginToKrita;



import React from "react";
import PrismCode from '../PrismCode'

import {Link} from "react-router-dom";

// https://react-icons.github.io/react-icons/icons?name=ai
// import icons individually. 
import { AiOutlineBulb } from "react-icons/ai"; 

const codeBlockOne = `
application = Krita.instance()
currentDoc = application.activeDocument()
currentLayer = currentDoc.activeNode()

# does layer have animation in it?
print( currentLayer.animated() )

# layer needs to be enabled to use animation functionality
currentLayer.enableAnimation()
`;

const codeBlockTwo = `
application = Krita.instance()
currentDoc = application.activeDocument()
currentLayer = currentDoc.activeNode()

# gets last animation frame that exists in our timeline
# this is different than the set playback time
print( currentDoc.animationLength()  )

# getter and setter for  current frame our playhead is on
currentDoc.currentTime()
currentDoc.setCurrentTime(24)

# set what frame range we want to use for playback
currentDoc.setPlaybackRange(0, 250)
currentDoc.playbackStartTime()
currentDoc.playBackEndTime()
`;

const codeBlockImportAnimations =  `
# list of files we would want to import
filesList = [ 'C:/users/anim-project/frame01.png' , 'C:/users/anim-project/frame02.png' , 'C:/users/anim-project/frame03.png' ]

# importAnimation(files, whatFrameToPlace, step)
# step is how long to hold each frame. Setting 2 would import images on frames spaced 2 apart
currentDoc.importAnimation(filesList, 0, 1) 
`;



const codeBlockActions = `
# playback
Krita.instance().action('stop_playback').trigger()
Krita.instance().action('toggle_playback').trigger()
Krita.instance().action('toggle_onion_skin').trigger()

# keyframe manipulation
Krita.instance().action('add_blank_frame').trigger() # add keyframe at current position
Krita.instance().action('remove_frames').trigger() # removes current frame
Krita.instance().action('insert_keyframe_right').trigger()
Krita.instance().action('insert_keyframe_left').trigger()
`;

const codeBlockExportActions = `
# will bring up a dialog with options to configure
Krita.instance().action('render_animation').trigger()

# takes previous settings from render animation and exports automatically
Krita.instance().action('render_animation_again').trigger()
`;





class LessonAnimation extends React.Component {

  // note: to use the code, you have to wrap it in   {`code goes here` } so Reeact can render it right
  // this is called ES5 template strings
  render() {
    return (
      <div>
        <h2>Activating animation</h2>

        <p>Animation is enabled by layer. By default layers are not able to be animated. The reason is that there are extra things that animated layers need to do that might slow down the application. We only want this technical overhead for layers that need animation. It is easy enough to change. Before you can manipulate the animation timeline or add keyframes, you need to make sure the layer has animation enabled. You can check if a layer has animation enabled and enable it like this </p>

       <PrismCode code={codeBlockOne} language="python" />

       <p>One visual thing you will notice with animation layers is that a light bulb icon will appear by the layer. This is for turning on and off onion skinning. </p>

       <h3>Moving around to different frames</h3>

       <p>Once your layer has animation enabled, you might want to move around the timeline or change properties. This is how you would do things like changing to a different frame or changing the playback range.</p>

       <PrismCode code={codeBlockTwo} language="python" />

      { /*  if using target... they seem to need rel="noopener noreferrer" to be valid for Angular  */ }
      <p>All of these properties are using the Krita library API.  If you want to learn more about what is available, check out the <a href="https://api.kde.org/krita/html/classDocument.html" target="_blank" rel="noopener noreferrer">Document API</a>. </p>


        <h3>Adding and removing frames, playback controls</h3>

        <p>Many common commands that you can access from the UI are available as actions. Like many things in scripting with Krita, if something doesn't exist in the Krita library API, you can check the available actions. Check out the <Link to="/action-dictionary">Action Dictionary</Link> to see what other actions are available if you are looking for other functionality.  </p>

        <PrismCode code={codeBlockActions} language="python" />

        <blockquote>
          <p><AiOutlineBulb /> Tip</p>
          <p>You can only affect one layer at a time with animation commands. You might need to change the active layer before you start adding frames, removing, or importing animations</p>
        </blockquote>


        <h3>Importing animation from external images</h3>

        <p>You might be using external programs to create animations. For your purpose you might just want to bring these frames into Krita for some touch-up or other work. To do this you will need to get a list of images that you want to import, then use the import animation method to pull them all in like this. </p>

        <PrismCode code={codeBlockImportAnimations} language="python" />

        <p>If you want to do something more complex like importing videos, you will have to work with external command line tools to extract the images from the video. If you want to see a more complex example of how this might be done using FFMPEG, you can take a look at Scott Petrovic's <a href="https://github.com/scottpetrovic/animator-video-reference" target="_blank" rel="noopener noreferrer" >Animator Video Reference plugin</a>.</p>


        <h3>Exporting animations</h3>

        <p>At this time there is no automatic animation exporting available with configurable options. There is some actions we can use that might work.</p>

        <PrismCode code={codeBlockExportActions} language="python" />

        <h3>Conclusion</h3>

        <p>We went over how to make a layer property animatable. We then went over some of the scripting way to move around the timeline, add and remove keyframes, and finally how to import and export out your animations.</p>

    
      </div>
    );
  }

}

export default LessonAnimation;



import React, {Component} from "react"
import { withRouter } from 'react-router-dom';

class WebAnalytics extends Component {
    constructor(props) {
    super(props)
    this.ref = React.createRef();

    // watch for router changes. Send new "page events" when the router changes
    // we have to do this manually since this whole is app is considerened one page
    props.history.listen( (location, action) => {
      var _paq= _paq || [];
      _paq.push(['setDocumentTitle',document.domain+"/"+document.title]);
      _paq.push(['trackPageView']);
    } );

  }
  

  // the regular code is a "self invoking anonymous function...which cannot be done with reactjs. 
  // This is the work-around"
  codePush(_paq) {
    var u = "//stats.kde.org/";
    var d = document, 
        g = d.createElement('script'),
        s = d.getElementsByTagName('script')[0];

    g.type = 'text/javascript';
    g.async = true;
    g.defer = true;
    g.src = u + 'piwik.js';
    s.parentNode.insertBefore(g,s);
  }


  render() {

    var _paq= _paq || [];

    // paq.push.name == 'push';  - what _pqa name is when it is not set
    if(_paq.push.name === 'push') {
      _paq.push(['setCookieDomain','*.krita.org']);
      _paq.push(['setDomains','*.krita.org']);
      _paq.push(['setDocumentTitle',document.domain+"/"+document.title]);
      _paq.push(['trackPageView']);
      _paq.push(['enableLinkTracking']);
      _paq.push(['setTrackerUrl', '//stats.kde.org/piwik.php']);
      _paq.push(['setSiteId', 13]);
      this.codePush(_paq); // generate object first...then we can set the Krita specific parameters

      console.log('sending matomo data as object...');
      console.log(_paq);
    }

    return (
      <div>
        <noscript><p><img src="//stats.kde.org/piwik.php?idsite=13" alt="" /></p></noscript>;
      </div>
    )
    
  }
}

export default withRouter(WebAnalytics);
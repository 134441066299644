import React from "react";
import PrismCode from '../PrismCode'

import {Link} from "react-router-dom";

const codeBlockOne = `
from PyQt5.QtWidgets import (QWidget, QAction)
import krita

# connection methods
def doCustomStuff():
    # add any functionality you want the menu to do
    # Note: this will not print to the output, but will print to the terminal
    print("action did stuff from new menu")

#create an action that does stuff
extractAction = QAction("Print a test statement to terminal")
extractAction.setShortcut("Ctrl+Q")
extractAction.setStatusTip('A simple test')
extractAction.triggered.connect(doCustomStuff)

# Create menu off main menu and add a new action to it
main_menu = Krita.instance().activeWindow().qwindow().menuBar()
custom_menu = main_menu.addMenu("New Actions Menu")
custom_menu.addAction(extractAction) # how to get items/actions in a menu
`;


const codeBlockContextMenu = `
from PyQt5.QtWidgets import QDialog, QHBoxLayout, QPushButton, QMenu

# add button and layout for button
layoutForButtons = QHBoxLayout()
newButton = QPushButton() 
newButton.setText( "Common Actions" )
layoutForButtons.addWidget(newButton)

# create menu
buttonMenu = QMenu()
newButton.setMenu(buttonMenu)

# "Actions" have to be added to menu
newAction = Krita.instance().action('help_about_app')
newActionTwo = Krita.instance().action('deselect')
buttonMenu.addAction(newAction)
buttonMenu.addAction(newActionTwo)

# create dialog  and show it
newDialog = QDialog() 
newDialog.setLayout(layoutForButtons)

# show the dialog
newDialog.show() 
newDialog.exec_()
`;


class LessonMenus extends React.Component {

  // note: to use the code, you have to wrap it in   {`code goes here` } so Reeact can render it right
  // this is called ES5 template strings
  render() {
    return (
      <div>

        <h2>Adding a new menu to the main menu</h2>

        <p>While adding things as dockers and custom user interfaces are nice, sometimes you need a collection of various items or actions. Adding things to the main menu could be a good option to keep everything together. Another advantage with menus is you can assign shortcuts to the actions that are easy to see and use. This is how you would create a new main menu area and add an item that does stuff.</p>

        <PrismCode code={codeBlockOne} language="python" />


        <h3>Adding menus to UI components</h3>

        <p>A few of the UI components like buttons have a way to add drop-down options. This is how we could turn a normal button into something that adds a list of options as a menu.</p>

        <PrismCode code={codeBlockContextMenu} language="python" />

        <p>We could have added our own created actions instead, but I wanted to show we can also re-use existing actions that come with Krita.</p>


      </div>
    );
  }

}

export default LessonMenus;



import React from "react";
import PrismCode from '../PrismCode'

// https://react-icons.github.io/react-icons/icons?name=ai
// import icons individually. 
import { AiOutlineBulb } from "react-icons/ai"; 

const codeBlockOne = `
print("Hello World")
`;


class LessonIntroduction extends React.Component {

  // note: to use the code, you have to wrap it in   {`code goes here` } so Reeact can render it right
  // this is called ES5 template strings
  render() {
    return (
      <div>

        <h2>Isn't Krita a drawing program?</h2>

        <p>Welcome to the wonderful world of scripting Krita. Scripting allows you to add features and modify many many areas in the Krita application. These are some of the benefits of scripting vs. trying to be a hardcore Krita developer</p>

        <ol>
          <li>No need to setup a development environment. This means you don't have to download the entire krita source code, download and install everything the source needs,  and figure out how to compile it. </li>
          <li>Avoid long compile times when you want to test out your code. Going from source code to a running Krita can sometimes take almost an hour. That is a long time to wait.</li>
          <li>Works equally well across all operating systems. This means you can run your scripts the same if you copy them between a Windows, Mac, or Linux operating system.</li>
          <li>It is very easy to share your scripts with other people online and have them try things out. For a hardcore Krita developer that uses C++, they have to make a special build of the entire application to try out changes.</li>

          <li>Uses Python which is better for newbies. This is a common language and not too hard to learn. If you were a hardcore Krita developer, you would have to learn C++, which has some pretty complicated concepts to learn like managing memory. </li>
        </ol>

        <p>This all means that it is easy to get started without a lot of technical knowledge. Let's see where we will be doing all our work in.</p>


        <blockquote>
          <p><AiOutlineBulb /> Tip</p>
          <p>There are some things scripting won't be able to do. With the help of the Krita community and Krita developers, new things are getting added all the time.</p>
        </blockquote>

        <h3>Overview of Scripter</h3>

        <p>Open Krita and there will be an option on the main menu <strong>Tools &gt; Scripts &gt; Scripter</strong>. A window will appear</p>

        <img src="/images/lessons/introduction-basic/scripter-widow.png" alt="Scripter Window" />

        <p>This is what we will refer to as “Scripter”. The top text area is where we will be writing code. The bottom area is where we can see the results and test things with the Output tab.</p>

        <h4>Change editor background color</h4>

        <p>If the top code part is white and you want to change the style like my image. This is done from the scripter main menu. From the Scripter menu, go to <strong>File &gt; Settings</strong>. There is an area called “Syntax Style”. The one I am using is called BreezeDarkSyntaxStyle. The window will update immediately as you change styles. There is no save button to confirm. This setting will be remembered the next time you start Krita, so you shouldn't have to change it again.</p>

        <h3>Run your first script</h3>

        <p>In the top area type the following:</p>

		    <PrismCode code={codeBlockOne} language="python" />

        <p>To run this, you need to press the play button in the toolbar. Press that and you should see the output like below.</p>

        <img src="/images/lessons/introduction-basic/scripter-basic-print.png" alt="Basic printing" />
        
        <p>The print() function will output something to the Output area. This will be used in the future a lot when we want to see how our script is behaving. The warning in the output is ok. It means that this script that we have is not saved anywhere. If we were to close Krita and open it later, we would lose our scripting.</p>


        <h3>Load and save scripts</h3>

      <p>You can load and save scripts from Scripter's main menu. There are a few options that you have available from the <strong>File</strong> menu in scripter.</p>

      <ol>
      <li><em>New</em> - Wipes out your current file and starts a new one. If you previously had an open file, it unlinks it from that one.</li>
        <li><em>Open</em> - Open a file from your computer.</li>
        <li><em>Reload File</em> - This is the same as opening up your file again. You will lose any changes that you have not saved.</li>
        <li><em>Save</em> - Saves a new file to your computer, or creates a new file to save.</li>
        <li><em>Save as</em> - Opens up the save dialog so you can save the file as a new file.</li>
      </ol>

      <p>When you are saving and loading files, the common file format that is used is a “py” file. When you save a script it might be called something like “my-custom-scripts.py”. The py extension is meant to communicate that this is a Python file.</p>


      <h2>Conclusion</h2>

      <p>In this lesson we learned a bit about why scripting is a good alternative for doing modifications to Krita. We also learned where to write scripts, how to customize the scripting window, run a script, and how to load and save script files. </p>


      </div>
    );
  }

}

export default LessonIntroduction;



import React from "react";
import PrismCode from '../PrismCode'

import {Link} from "react-router-dom";

const codeBlockOne = `
from krita import *

dockersList = Krita.instance().dockers()

for docker in dockersList:
    print(docker.objectName()) # see all the names...useful for cherry-picking one
`;


const codeBlockToggleDocker = `
from krita import *

dockersList = Krita.instance().dockers()

for docker in dockersList:

    # target the Advanced Color selector which has an odd name
    if(docker.objectName() == 'ColorSelectorNg'):
        docker.setVisible( not docker.isVisible()  )
`;


const codeBlockRaiseDocker = `
from krita import *

dockersList = Krita.instance().dockers()

for docker in dockersList:

    # target the Advanced Color selector which has an odd name
    if(docker.objectName() == 'ColorSelectorNg'):
        advancedColorDocker = docker;

# toggle the visibility of the docker
advancedColorDocker.setVisible( not advancedColorDocker.isVisible()  )       

# see if the widget is actually shown..or is an inactive tab
print( advancedColorDocker.visibleRegion().isEmpty() )

# makes sure the docker is shown if it is in a set of tabs
advancedColorDocker.raise_()
`;

class LessonDockers extends React.Component {

  // note: to use the code, you have to wrap it in   {`code goes here` } so Reeact can render it right
  // this is called ES5 template strings
  render() {
    return (
      <div>
        <h2>Retrieving dockers</h2>

        <p>Dockers are all the panels in Krita that you can move around and attach to the sides. They can float around or dock and be part of a tab system. Through scripting we have access to these dockers if we wish to make changes or control them. To get a list of all the dockers that we are available we can do something like this.</p>

        <PrismCode code={codeBlockOne} language="python" />

        <p>From this list we can do many things. Since we have a full list and can see their names, we can grab a specific docker and do actions on it like hiding and showing.</p>

        <h3>Toggling visibility</h3>

        <p>One you have a reference to a docker, you can start manipulating its properties. Here is one example of looking for the advanced color selector, then toggling its visibility on and off</p>

        <PrismCode code={codeBlockToggleDocker} language="python" />


        <h3>Activating a docker in a set of tabs</h3>

        <p>Because of how dockers stack, it is possible that after you show a docker, it will be inactive because there are other dockers already in the same area. You might want to force the docker to appear instead of people having to click the inactive tab to active it. This is how you would do that.</p>

        <PrismCode code={codeBlockRaiseDocker} language="python" />

        <p>I also re-arranged the code a bit to see how you can save references to specific dockers. This could be helpful if you wanted to add a UI where clicking buttons would trigger the visibility state. </p>


        <h3>Accessing additional areas of Dockers</h3>

        <p>There are additional things that you can control in dockers beyond this. There are things like editing the title bar and modifying what areas you allow the docker to be docked on.</p>


        <p>The docker functionality that Krita uses is part of the PyQt library. Because of this, we have a large amount of functionality available for us. Here is the Qt documentation for the <a href="https://doc.qt.io/qt-5/qdockwidget.html" target="_blank" rel="noopener noreferrer"> QDockWidget</a> with what we can do. If you aren't familiar with C++ and how to use an API reference, you might want to take a look at the  <Link to="/lessons/reference-api-pyqt">PyQt5 reference</Link> page to see how to look through the API or translate C++ to Python.  </p>


      </div>
    );
  }

}

export default LessonDockers;



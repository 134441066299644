import React from "react";
import Lessons from "./Lessons"
import ScrollToTop from "./ScrollToTop"
import WebAnalytics from "./WebAnalytics"

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';


// https://react-icons.github.io/react-icons/icons?name=ai
// import icons individually. 
import { AiFillBulb, AiFillFileText, AiOutlineBook } from "react-icons/ai"; 

// routing
import {
  Switch,
  Route,
  NavLink,
  Redirect,
  BrowserRouter
} from "react-router-dom";


// components
import ActionDictionary from './ActionDictionary.jsx'
import Home from './Home.jsx'
import IconLibrary from './IconLibrary.jsx'



export default function App() {

  const [selectedTheme, setSelectedTheme] = React.useState('light');

  let iconLibraryRef = React.createRef();

  const handleChange = (event, newAlignment) => {
    if (newAlignment && newAlignment.length) {
      setSelectedTheme(newAlignment);

      // tell the icon library we need to update our icons to the right theme color
      // Note: we're accessing "current" to get the DOM node
      // reference is occasionally null depending on the lifecycle state
      if(iconLibraryRef && iconLibraryRef.current) {
        iconLibraryRef.current.changedTheme(newAlignment);
      }

    }
  };


  return (
    <BrowserRouter >
      <div>
        <span className={selectedTheme + '-theme'}>
        <ScrollToTop />
        <div className="header-top-bar">
          <nav>
            <ul>
              <li>
                <NavLink to="/" >
                    <img src="/images/krita-logo.svg" height="16" width="16" alt="" />
                    <span className="branding-krita-name">KRITA</span> 
                    <span className="branding-krita-slogan">Scripting School</span>
                </NavLink>
              </li>
              <li className="desktop-navigation">
                <NavLink to="/lessons"  activeClassName="active-nav-link">Lessons</NavLink>
              </li>

              <li className="desktop-navigation">
                <NavLink to="/action-dictionary" activeClassName="active-nav-link">Action Dictionary</NavLink>
              </li>
              <li className="desktop-navigation">
                <NavLink to="/icon-library" activeClassName="active-nav-link">Icon Library</NavLink>
              </li>
              <li>

                <ToggleButtonGroup size="small" value={selectedTheme} exclusive onChange={handleChange}>
                  <ToggleButton value="light">
                    Light
                  </ToggleButton>
                  <ToggleButton value="dark">
                    Dark
                  </ToggleButton>
                </ToggleButtonGroup>
              </li>
            </ul>






          </nav>

          <nav className="mobile">
            <ul>
            <li>               
                 <NavLink to="/lessons"  activeClassName="active-nav-link">
                   <div className="mobile-nav-icon"><AiFillFileText /></div>
                   Lessons
                   </NavLink>
              </li>

              <li>
                <NavLink to="/action-dictionary" activeClassName="active-nav-link">
                  <div className="mobile-nav-icon"><AiFillBulb /></div>
                  Actions
                  </NavLink>
              </li>

              <li>
                  <NavLink to="/icon-library" activeClassName="active-nav-link">
                  <div className="mobile-nav-icon"><AiOutlineBook /></div>
                    Icon Library
                    </NavLink>
              </li>
            </ul>
          </nav>



        </div>
       

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/" exact>
            <Redirect to="/lessons" />
            {/* <Redirect to="/home" /> */}
          </Route>
          <Route path="/action-dictionary" component={ActionDictionary} />
          <Route path="/icon-library" >
              <IconLibrary ref={iconLibraryRef} theme={selectedTheme} />
          </Route>
          <Route path="/home" exact component={Home} />
          <Route path="/lessons" component={Lessons} />

        </Switch>

        </span>

        <WebAnalytics></WebAnalytics>

      </div>
    </BrowserRouter>
  );
}

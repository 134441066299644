// if you need new data, check out the generat-icons.py script in the source directory. 
// You can copy that output and update the data in this area
const IconDictionaryData = 
[
    {
        "id": 0,
        "name": "krita_tool_gradient",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 1,
        "name": "move-tool",
        "format": "png",
        "isThemed": false,
        "theme": "none"
    },
    {
        "id": 2,
        "name": "document-save",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 3,
        "name": "curvebrush",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 4,
        "name": "22_light_format-list-ordered",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 5,
        "name": "krita_tool_polygon",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 6,
        "name": "paintLayer",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 7,
        "name": "dynabrush",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 8,
        "name": "tool_crop",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 9,
        "name": "transparency-disabled",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 10,
        "name": "krita_tool_multihand",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 11,
        "name": "broken-preset",
        "format": "svgz",
        "isThemed": false,
        "theme": "none"
    },
    {
        "id": 12,
        "name": "addlayer",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 13,
        "name": "transform_icons_liquify_rotate",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 14,
        "name": "sketchbrush",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 15,
        "name": "24_light_format-text-subscript",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 16,
        "name": "interpolation_bezier",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 17,
        "name": "wheel-light",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 18,
        "name": "22_dark_object-align-horizontal-center-calligra",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 19,
        "name": "16_light_format-text-bold",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 20,
        "name": "bristlebrush",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 21,
        "name": "system-help",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 22,
        "name": "zoom-horizontal",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 23,
        "name": "64_dark_application-x-krita",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 24,
        "name": "curve-preset-s-reverse",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 25,
        "name": "pallete_librarysvg",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 26,
        "name": "tool_contiguous_selection",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 27,
        "name": "animation_stop",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 28,
        "name": "22_light_format-text-direction-rtl",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 29,
        "name": "22_light_format-justify-fill",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 30,
        "name": "22_dark_format-justify-center",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 31,
        "name": "transform_icons_mirror_x",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 32,
        "name": "geometry",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 33,
        "name": "16_light_object-align-vertical-top-calligra",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 34,
        "name": "format-list-unordered",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 35,
        "name": "22_light_zoom-fit-best",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 36,
        "name": "ox16-action-object-align-vertical-top-calligra",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 37,
        "name": "22_light_draw-arrow-forward",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 38,
        "name": "24_light_draw-arrow-down",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 39,
        "name": "transform_icons_liquify_move",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 40,
        "name": "22_dark_shape-choose",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 41,
        "name": "24_light_format-text-bold",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 42,
        "name": "draw-text",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 43,
        "name": "pathpoint-insert",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 44,
        "name": "groupOpened",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 45,
        "name": "document-save",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 46,
        "name": "transform_icons_liquify_move",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 47,
        "name": "nextkeyframe",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 48,
        "name": "16_light_format-justify-right",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 49,
        "name": "16_dark_object-align-vertical-top-calligra",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 50,
        "name": "16_light_format-text-superscript",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 51,
        "name": "curve-preset-s",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 52,
        "name": "16_dark_character-set",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 53,
        "name": "select-all",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 54,
        "name": "layer-style-enabled",
        "format": "svg",
        "isThemed": false,
        "theme": "none"
    },
    {
        "id": 55,
        "name": "media-record",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 56,
        "name": "16_light_split",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 57,
        "name": "document-print-preview",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 58,
        "name": "24_light_draw-freehand",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 59,
        "name": "transform_icons_liquify_resize",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 60,
        "name": "22_dark_application-x-krita",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 61,
        "name": "ox16-action-object-align-vertical-center-calligra",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 62,
        "name": "showColoringOff",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 63,
        "name": "24_dark_tab-new",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 64,
        "name": "tool_contiguous_selection",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 65,
        "name": "krita_tool_dyna",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 66,
        "name": "transform_icons_liquify_resize",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 67,
        "name": "bundle_archive",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 68,
        "name": "preferences-desktop-locale",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 69,
        "name": "colorizeMask",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 70,
        "name": "transform_icons_liquify_erase",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 71,
        "name": "smoothing-weighted",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 72,
        "name": "zoom-vertical",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 73,
        "name": "22_dark_draw-arrow-forward",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 74,
        "name": "krita_tool_assistant",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 75,
        "name": "16_dark_trash-empty",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 76,
        "name": "lastframe",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 77,
        "name": "view-list-details",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 78,
        "name": "24_dark_format-justify-left",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 79,
        "name": "24_dark_format-text-italic",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 80,
        "name": "22_light_object-group-calligra",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 81,
        "name": "window-new",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 82,
        "name": "distribute-horizontal-right",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 83,
        "name": "24_light_format-justify-right",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 84,
        "name": "22_light_snap-node",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 85,
        "name": "24_dark_align-vertical-center",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 86,
        "name": "geometry",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 87,
        "name": "stroke-cap-butt",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 88,
        "name": "rotate-canvas-right",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 89,
        "name": "22_light_draw-arrow-up",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 90,
        "name": "16_light_align-vertical-center",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 91,
        "name": "warning",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 92,
        "name": "selection-mode_invisible",
        "format": "svg",
        "isThemed": false,
        "theme": "none"
    },
    {
        "id": 93,
        "name": "locked",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 94,
        "name": "hatchingbrush",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 95,
        "name": "ox16-action-object-order-lower-calligra",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 96,
        "name": "24_light_insert-text",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 97,
        "name": "pattern",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 98,
        "name": "24_dark_snap-extension",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 99,
        "name": "removefromfolder",
        "format": "png",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 100,
        "name": "interpolation_constant",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 101,
        "name": "22_dark_align-horizontal-left",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 102,
        "name": "16_dark_format-justify-right",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 103,
        "name": "auto-key-on",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 104,
        "name": "22_dark_edit-rename",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 105,
        "name": "addduplicateframe",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 106,
        "name": "krita_tool_freehand",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 107,
        "name": "22_light_format-text-underline",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 108,
        "name": "ox16-action-object-order-raise-calligra",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 109,
        "name": "smoothing-basic",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 110,
        "name": "applications-system",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 111,
        "name": "gamut-mask-off",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 112,
        "name": "filterMask",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 113,
        "name": "24_light_split",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 114,
        "name": "22_dark_format-text-bold",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 115,
        "name": "addblankframe",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 116,
        "name": "preset-switcher",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 117,
        "name": "22_light_application-x-wmf",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 118,
        "name": "pattern",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 119,
        "name": "24_dark_align-vertical-top",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 120,
        "name": "24_dark_snap-intersection",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 121,
        "name": "zoom-original",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 122,
        "name": "tool_perspectivegrid",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 123,
        "name": "mirror-view",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 124,
        "name": "window-close",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 125,
        "name": "cloneLayer",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 126,
        "name": "document-print-preview",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 127,
        "name": "transparency-enabled",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 128,
        "name": "pathsegment-line",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 129,
        "name": "ox16-action-object-align-horizontal-left-calligra",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 130,
        "name": "krita_tool_smart_patch",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 131,
        "name": "gamut-mask-on",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 132,
        "name": "audio-volume-mute",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 133,
        "name": "arrowupblr",
        "format": "png",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 134,
        "name": "tool_pan",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 135,
        "name": "16_dark_application-x-wmf",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 136,
        "name": "showColoring",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 137,
        "name": "16_light_warning",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 138,
        "name": "unlocked",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 139,
        "name": "16_light_format-stroke-color",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 140,
        "name": "krita_tool_rectangle",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 141,
        "name": "shape_handling",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 142,
        "name": "22_light_snap-guideline",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 143,
        "name": "media-playback-stop",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 144,
        "name": "pathpoint-remove",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 145,
        "name": "distribute-horizontal-left",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 146,
        "name": "24_dark_edit-select-all",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 147,
        "name": "16_light_align-horizontal-right",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 148,
        "name": "tool_path_selection",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 149,
        "name": "nextkeyframe",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 150,
        "name": "transform_icons_penPressure_locked",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 151,
        "name": "16_light_align-horizontal-left",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 152,
        "name": "16_dark_align-vertical-bottom",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 153,
        "name": "curve-preset-u",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 154,
        "name": "24_light_edit-select-all",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 155,
        "name": "zoom-vertical",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 156,
        "name": "22_light_edit-rename",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 157,
        "name": "curve-preset-j",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 158,
        "name": "addlayer",
        "format": "png",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 159,
        "name": "folder-pictures",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 160,
        "name": "tool_outline_selection",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 161,
        "name": "24_light_format-justify-left",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 162,
        "name": "interpolation_sharp",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 163,
        "name": "removefromfolder",
        "format": "png",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 164,
        "name": "colorizeMask",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 165,
        "name": "transform_icons_rotate_cw",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 166,
        "name": "groupLayer",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 167,
        "name": "zoom-horizontal",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 168,
        "name": "onionOn",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 169,
        "name": "split-layer",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 170,
        "name": "transform_icons_liquify_erase",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 171,
        "name": "tool_path_selection",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 172,
        "name": "deletelayer",
        "format": "png",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 173,
        "name": "lightness-increase",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 174,
        "name": "stroke-join-miter",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 175,
        "name": "24_dark_tab-close",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 176,
        "name": "drive-harddisk",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 177,
        "name": "krita_tool_transform_recursive",
        "format": "png",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 178,
        "name": "zoom-in",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 179,
        "name": "pathpoint-smooth",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 180,
        "name": "curve-preset-linear",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 181,
        "name": "tool_similar_selection",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 182,
        "name": "removefromfolder",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 183,
        "name": "22_light_snap-intersection",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 184,
        "name": "48_light_klipper",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 185,
        "name": "prevframe",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 186,
        "name": "ox16-action-object-align-horizontal-right-calligra",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 187,
        "name": "22_dark_format-text-subscript",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 188,
        "name": "arrow-topright",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 189,
        "name": "lightness-decrease",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 190,
        "name": "krita_tool_transform",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 191,
        "name": "dialog-cancel",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 192,
        "name": "arrow-down",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 193,
        "name": "22_dark_object-group-calligra",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 194,
        "name": "22_light_align-horizontal-left",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 195,
        "name": "64_dark_application-x-wmf",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 196,
        "name": "24_dark_insert-text",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 197,
        "name": "python",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 198,
        "name": "addtofolder",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 199,
        "name": "ox16-action-object-align-horizontal-left-calligra",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 200,
        "name": "22_light_insert-text",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 201,
        "name": "lightness-increase",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 202,
        "name": "nextframe",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 203,
        "name": "ox16-action-object-align-horizontal-right-calligra",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 204,
        "name": "22_light_tab-new",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 205,
        "name": "24_dark_align-horizontal-center",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 206,
        "name": "krita_tool_move",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 207,
        "name": "22_light_format-indent-less",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 208,
        "name": "snapshot-load",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 209,
        "name": "layer-style-disabled",
        "format": "svg",
        "isThemed": false,
        "theme": "none"
    },
    {
        "id": 210,
        "name": "16_dark_align-horizontal-right",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 211,
        "name": "timeline_keyframe",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 212,
        "name": "interpolation_linear",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 213,
        "name": "application-pdf",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 214,
        "name": "document-open-recent",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 215,
        "name": "merge-layer-below",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 216,
        "name": "krita_draw_path",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 217,
        "name": "distribute-horizontal-center",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 218,
        "name": "krita_tool_rectangle",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 219,
        "name": "folder-documents",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 220,
        "name": "16_light_object-align-horizontal-center-calligra",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 221,
        "name": "zoom-fit",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 222,
        "name": "gridbrush",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 223,
        "name": "locked",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 224,
        "name": "24_light_snap-orthogonal",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 225,
        "name": "krita_tool_transform",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 226,
        "name": "object-rotate-left",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 227,
        "name": "rotation-reset",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 228,
        "name": "tool_magnetic_selection",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 229,
        "name": "curve-preset-linear",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 230,
        "name": "22_dark_align-vertical-center",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 231,
        "name": "22_light_object-align-horizontal-center-calligra",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 232,
        "name": "16_dark_list-remove",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 233,
        "name": "pivot-point",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 234,
        "name": "16_dark_transform-move",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 235,
        "name": "24_light_draw-arrow-forward",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 236,
        "name": "22_dark_format-text-strikethrough",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 237,
        "name": "krita_tool_freehand",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 238,
        "name": "transform_icons_liquify_rotateCCW",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 239,
        "name": "onion_skin_options",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 240,
        "name": "16_light_application-x-wmf",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 241,
        "name": "edit-delete",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 242,
        "name": "24_light_format-text-strikethrough",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 243,
        "name": "dialog-ok",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 244,
        "name": "distribute-vertical-top",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 245,
        "name": "go-home",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 246,
        "name": "update-medium",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 247,
        "name": "22_dark_align-horizontal-right",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 248,
        "name": "object-rotate-right",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 249,
        "name": "24_dark_format-stroke-color",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 250,
        "name": "krita_tool_measure",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 251,
        "name": "document-edit",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 252,
        "name": "tools-report-bug",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 253,
        "name": "16_dark_format-indent-less",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 254,
        "name": "24_dark_format-text-direction-rtl",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 255,
        "name": "16_dark_tab-close",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 256,
        "name": "pathsegment-line",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 257,
        "name": "view-list-text",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 258,
        "name": "draw-eraser",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 259,
        "name": "22_light_draw-arrow-down",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 260,
        "name": "animation_pause",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 261,
        "name": "smoothing-no",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 262,
        "name": "symmetry-vertical",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 263,
        "name": "layer-locked",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 264,
        "name": "arrow-up",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 265,
        "name": "filterLayer",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 266,
        "name": "hatchingbrush",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 267,
        "name": "24_light_tab-new",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 268,
        "name": "24_light_format-text-italic",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 269,
        "name": "22_dark_zoom-fit-best",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 270,
        "name": "selection-mode_mask",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 271,
        "name": "22_light_format-text-strikethrough",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 272,
        "name": "ox16-action-object-order-front-calligra",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 273,
        "name": "filterbrush",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 274,
        "name": "32_dark_application-x-wmf",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 275,
        "name": "curve-preset-j",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 276,
        "name": "22_light_format-text-subscript",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 277,
        "name": "distribute-horizontal-right",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 278,
        "name": "process-stop",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 279,
        "name": "selection-mode_ants",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 280,
        "name": "24_light_snap-extension",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 281,
        "name": "arrow-left",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 282,
        "name": "quickbrush",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 283,
        "name": "tool_rect_selection",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 284,
        "name": "22_dark_application-x-wmf",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 285,
        "name": "24_dark_snap-orthogonal",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 286,
        "name": "16_light_format-text-italic",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 287,
        "name": "krita_tool_grid",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 288,
        "name": "tools-wizard",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 289,
        "name": "properties",
        "format": "png",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 290,
        "name": "22_dark_snap-node",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 291,
        "name": "path-break-segment",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 292,
        "name": "24_light_transform-move",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 293,
        "name": "document-save-as",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 294,
        "name": "krita_tool_freehandvector",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 295,
        "name": "16_light_format-text-color",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 296,
        "name": "interpolation_constant",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 297,
        "name": "document-save-as",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 298,
        "name": "transform_icons_warp",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 299,
        "name": "22_light_align-vertical-top",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 300,
        "name": "16_dark_shape-choose",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 301,
        "name": "22_light_align-horizontal-center",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 302,
        "name": "docker_lock_b",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 303,
        "name": "ox16-action-object-align-horizontal-center-calligra",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 304,
        "name": "22_dark_snap-intersection",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 305,
        "name": "24_light_snap-intersection",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 306,
        "name": "edit-copy",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 307,
        "name": "media-playback-start",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 308,
        "name": "stroke-join-round",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 309,
        "name": "gridbrush",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 310,
        "name": "dialog-warning",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 311,
        "name": "transformMask",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 312,
        "name": "24_dark_list-remove",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 313,
        "name": "transform_icons_penPressure",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 314,
        "name": "addtofolder",
        "format": "png",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 315,
        "name": "animation_play",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 316,
        "name": "24_light_format-text-underline",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 317,
        "name": "16_dark_format-text-color",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 318,
        "name": "16_light_draw-freehand",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 319,
        "name": "16_light_align-vertical-bottom",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 320,
        "name": "transform_icons_liquify_main",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 321,
        "name": "properties",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 322,
        "name": "tools-report-bug",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 323,
        "name": "24_dark_draw-arrow-forward",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 324,
        "name": "krita_tool_transform_recursive",
        "format": "png",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 325,
        "name": "pallete_library",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 326,
        "name": "clonebrush",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 327,
        "name": "22_dark_draw-arrow-down",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 328,
        "name": "duplicatelayer",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 329,
        "name": "22_dark_character-set",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 330,
        "name": "24_dark_align-horizontal-left",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 331,
        "name": "24_light_snap-guideline",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 332,
        "name": "22_light_application-x-krita",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 333,
        "name": "media-playback-start",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 334,
        "name": "krita_tool_smart_patch",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 335,
        "name": "arrowdown",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 336,
        "name": "16_dark_format-justify-left",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 337,
        "name": "document-new",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 338,
        "name": "krita_tool_polygon",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 339,
        "name": "preset-switcher",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 340,
        "name": "16_dark_snap-extension",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 341,
        "name": "draw-text",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 342,
        "name": "krita_tool_color_fill",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 343,
        "name": "22_dark_edit-select-all",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 344,
        "name": "smoothing-no",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 345,
        "name": "path-break-segment",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 346,
        "name": "folder-documents",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 347,
        "name": "audio-volume-mute",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 348,
        "name": "symmetry-vertical",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 349,
        "name": "16_light_align-horizontal-center",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 350,
        "name": "pathsegment-curve",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 351,
        "name": "arrowdown",
        "format": "png",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 352,
        "name": "deformbrush",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 353,
        "name": "edit-paste",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 354,
        "name": "transparencyMask",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 355,
        "name": "color-to-alpha",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 356,
        "name": "16_dark_format-justify-fill",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 357,
        "name": "16_light_transform-move",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 358,
        "name": "22_dark_insert-text",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 359,
        "name": "16_dark_snap-guideline",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 360,
        "name": "paintop_settings_02",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 361,
        "name": "colorsmudge",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 362,
        "name": "vectorLayer",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 363,
        "name": "16_dark_align-vertical-top",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 364,
        "name": "24_dark_format-justify-center",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 365,
        "name": "kde",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 366,
        "name": "selection-info",
        "format": "svg",
        "isThemed": false,
        "theme": "none"
    },
    {
        "id": 367,
        "name": "view-filter",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 368,
        "name": "passthrough-enabled",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 369,
        "name": "22_dark_object-align-horizontal-left-calligra",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 370,
        "name": "22_dark_format-justify-right",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 371,
        "name": "pivot-point",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 372,
        "name": "folder-pictures",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 373,
        "name": "rotation-reset",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 374,
        "name": "shape_handling",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 375,
        "name": "distribute-vertical-top",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 376,
        "name": "distribute-horizontal-center",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 377,
        "name": "view-refresh",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 378,
        "name": "smoothing-stabilizer",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 379,
        "name": "klipper",
        "format": "svg",
        "isThemed": false,
        "theme": "none"
    },
    {
        "id": 380,
        "name": "transform_icons_warp",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 381,
        "name": "view-list-text",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 382,
        "name": "tool_polygonal_selection",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 383,
        "name": "smoothing-basic",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 384,
        "name": "transform_icons_penPressure",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 385,
        "name": "distribute-horizontal-left",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 386,
        "name": "16_light_format-indent-less",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 387,
        "name": "preferences-desktop-color",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 388,
        "name": "22_light_format-justify-right",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 389,
        "name": "python",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 390,
        "name": "pathpoint-insert",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 391,
        "name": "spraybrush",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 392,
        "name": "16_dark_format-justify-center",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 393,
        "name": "animation_pause",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 394,
        "name": "24_light_align-vertical-bottom",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 395,
        "name": "view-list-details",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 396,
        "name": "24_light_draw-arrow-back",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 397,
        "name": "view-preview",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 398,
        "name": "24_light_align-horizontal-center",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 399,
        "name": "ox16-action-object-order-back-calligra",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 400,
        "name": "showMarks",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 401,
        "name": "distribute-vertical-center",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 402,
        "name": "filterMask",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 403,
        "name": "pathpoint-smooth",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 404,
        "name": "16_light_zoom-fit-best",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 405,
        "name": "application-exit",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 406,
        "name": "stroke-cap-square",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 407,
        "name": "document-import",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 408,
        "name": "link",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 409,
        "name": "fileLayer",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 410,
        "name": "transform_icons_penPressure_locked",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 411,
        "name": "24_dark_align-vertical-bottom",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 412,
        "name": "22_light_list-remove",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 413,
        "name": "portrait",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 414,
        "name": "dropframe",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 415,
        "name": "trim-to-image",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 416,
        "name": "16_light_format-text-strikethrough",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 417,
        "name": "selection-mode_invisible",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 418,
        "name": "24_light_list-remove",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 419,
        "name": "krita-base",
        "format": "svg",
        "isThemed": false,
        "theme": "none"
    },
    {
        "id": 420,
        "name": "16_light_draw-arrow-forward",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 421,
        "name": "16_light_trash-empty",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 422,
        "name": "bookmarks",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 423,
        "name": "view-grid",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 424,
        "name": "transform_icons_rotate_ccw",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 425,
        "name": "infinity",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 426,
        "name": "pathpoint-symmetric",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 427,
        "name": "ox16-action-object-order-raise-calligra",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 428,
        "name": "link",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 429,
        "name": "document-open-recent",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 430,
        "name": "arrow-right",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 431,
        "name": "smoothing-weighted",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 432,
        "name": "22_dark_format-text-underline",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 433,
        "name": "32_dark_application-x-krita",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 434,
        "name": "view-choose",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 435,
        "name": "transform_icons_perspective",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 436,
        "name": "24_light_align-horizontal-left",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 437,
        "name": "16_dark_format-text-subscript",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 438,
        "name": "audio-volume-high",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 439,
        "name": "24_light_format-text-superscript",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 440,
        "name": "document-new",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 441,
        "name": "transform_icons_mirror_y",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 442,
        "name": "format-fill-color",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 443,
        "name": "quickbrush",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 444,
        "name": "16_light_align-vertical-top",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 445,
        "name": "wheel-rings",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 446,
        "name": "list-add",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 447,
        "name": "gamut-mask-off",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 448,
        "name": "16_light_format-justify-fill",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 449,
        "name": "list-add",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 450,
        "name": "interpolation_smooth",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 451,
        "name": "transform_icons_cage",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 452,
        "name": "duplicatelayer",
        "format": "png",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 453,
        "name": "interpolation_linear",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 454,
        "name": "tool_crop",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 455,
        "name": "tag",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 456,
        "name": "configure",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 457,
        "name": "prevkeyframe",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 458,
        "name": "24_light_tab-close",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 459,
        "name": "curve-preset-l",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 460,
        "name": "22_dark_format-text-italic",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 461,
        "name": "pixelbrush",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 462,
        "name": "16_light_edit-rename",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 463,
        "name": "docker_lock_b",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 464,
        "name": "docker_lock_a",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 465,
        "name": "view-refresh",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 466,
        "name": "tag",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 467,
        "name": "showColoring",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 468,
        "name": "22_light_format-text-superscript",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 469,
        "name": "16_dark_tab-new",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 470,
        "name": "ox16-action-object-align-vertical-top-calligra",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 471,
        "name": "transform_icons_perspective",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 472,
        "name": "16_dark_split",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 473,
        "name": "arrow-downright",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 474,
        "name": "polyline",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 475,
        "name": "22_dark_format-text-superscript",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 476,
        "name": "brushsize-increase",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 477,
        "name": "layer-unlocked",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 478,
        "name": "24_dark_format-text-strikethrough",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 479,
        "name": "16_light_character-set",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 480,
        "name": "select-clear",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 481,
        "name": "opacity-decrease",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 482,
        "name": "16_light_snap-guideline",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 483,
        "name": "22_light_format-stroke-color",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 484,
        "name": "16_dark_draw-arrow-down",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 485,
        "name": "arrow-downright",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 486,
        "name": "onionOn",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 487,
        "name": "tool_color_picker_cursor",
        "format": "png",
        "isThemed": false,
        "theme": "none"
    },
    {
        "id": 488,
        "name": "22_dark_tab-close",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 489,
        "name": "pathpoint-join",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 490,
        "name": "ox16-action-object-group-calligra",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 491,
        "name": "update-medium",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 492,
        "name": "edit-cut",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 493,
        "name": "16_dark_application-x-krita",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 494,
        "name": "22_dark_format-justify-left",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 495,
        "name": "pixelbrush",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 496,
        "name": "go-home",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 497,
        "name": "auto-key-off",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 498,
        "name": "22_light_object-align-vertical-top-calligra",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 499,
        "name": "22_dark_format-list-ordered",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 500,
        "name": "paintLayer",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 501,
        "name": "krita_tool_color_picker",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 502,
        "name": "22_dark_format-indent-less",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 503,
        "name": "showMarksOff",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 504,
        "name": "particlebrush",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 505,
        "name": "16_light_list-remove",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 506,
        "name": "pathpoint-merge",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 507,
        "name": "calligraphy",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 508,
        "name": "tool_rect_selection",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 509,
        "name": "16_light_shape-choose",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 510,
        "name": "application-pdf",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 511,
        "name": "24_light_align-horizontal-right",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 512,
        "name": "22_light_split",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 513,
        "name": "audio-volume-high",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 514,
        "name": "onionOff",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 515,
        "name": "ratio",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 516,
        "name": "22_dark_format-indent-more",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 517,
        "name": "pathpoint-corner",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 518,
        "name": "ox16-action-object-order-lower-calligra",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 519,
        "name": "draw-eraser",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 520,
        "name": "onion_skin_options",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 521,
        "name": "gmic",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 522,
        "name": "24_light_character-set",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 523,
        "name": "keyframe-add",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 524,
        "name": "ox16-action-object-order-back-calligra",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 525,
        "name": "animation_stop",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 526,
        "name": "folder",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 527,
        "name": "16_dark_object-align-horizontal-center-calligra",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 528,
        "name": "16_dark_object-align-horizontal-left-calligra",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 529,
        "name": "22_dark_align-vertical-top",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 530,
        "name": "16_dark_snap-node",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 531,
        "name": "tool_pan",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 532,
        "name": "passthrough-disabled",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 533,
        "name": "interpolation_smooth",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 534,
        "name": "layer-unlocked",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 535,
        "name": "preferences-desktop-locale",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 536,
        "name": "window-close",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 537,
        "name": "dialog-warning",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 538,
        "name": "16_dark_align-horizontal-center",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 539,
        "name": "krita_tool_dyna",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 540,
        "name": "stroke-cap-square",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 541,
        "name": "bookmarks",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 542,
        "name": "16_light_format-indent-more",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 543,
        "name": "24_dark_snap-guideline",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 544,
        "name": "bundle_archive",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 545,
        "name": "nextframe",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 546,
        "name": "16_light_draw-arrow-up",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 547,
        "name": "edit-clear",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 548,
        "name": "24_light_format-justify-fill",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 549,
        "name": "brushsize-increase",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 550,
        "name": "24_light_format-text-direction-rtl",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 551,
        "name": "16_dark_format-text-bold",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 552,
        "name": "stroke-join-bevel",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 553,
        "name": "24_dark_align-horizontal-right",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 554,
        "name": "arrow-downleft",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 555,
        "name": "curve-preset-arch",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 556,
        "name": "edit-delete",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 557,
        "name": "22_dark_format-text-color",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 558,
        "name": "krita_tool_reference_images",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 559,
        "name": "merge-layer-below",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 560,
        "name": "22_dark_format-stroke-color",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 561,
        "name": "22_light_format-text-italic",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 562,
        "name": "document-open",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 563,
        "name": "curve-preset-u",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 564,
        "name": "curve-preset-s-reverse",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 565,
        "name": "smoothing-stabilizer",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 566,
        "name": "tool_elliptical_selection",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 567,
        "name": "22_light_format-justify-left",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 568,
        "name": "22_light_format-indent-more",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 569,
        "name": "22_dark_snap-guideline",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 570,
        "name": "ox16-action-object-group-calligra",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 571,
        "name": "16_dark_warning",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 572,
        "name": "deletelayer",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 573,
        "name": "addtofolder",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 574,
        "name": "format-list-unordered",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 575,
        "name": "arrow-topleft",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 576,
        "name": "stroke-cap-round",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 577,
        "name": "16_light_media-floppy",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 578,
        "name": "artistic_text",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 579,
        "name": "24_dark_format-list-ordered",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 580,
        "name": "onionOff",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 581,
        "name": "lightness-decrease",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 582,
        "name": "22_light_draw-arrow-back",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 583,
        "name": "view-choose",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 584,
        "name": "format-fill-color",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 585,
        "name": "ox16-action-object-align-horizontal-center-calligra",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 586,
        "name": "filterbrush",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 587,
        "name": "24_light_align-vertical-top",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 588,
        "name": "22_dark_transform-move",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 589,
        "name": "dropframe",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 590,
        "name": "addblankframe",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 591,
        "name": "24_light_trash-empty",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 592,
        "name": "media-record",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 593,
        "name": "animation_play",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 594,
        "name": "16_dark_format-indent-more",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 595,
        "name": "krita_tool_freehandvector",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 596,
        "name": "deletelayer",
        "format": "png",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 597,
        "name": "pathpoint-remove",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 598,
        "name": "distribute-vertical",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 599,
        "name": "22_dark_media-floppy",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 600,
        "name": "download",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 601,
        "name": "24_dark_format-justify-fill",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 602,
        "name": "passthrough-enabled",
        "format": "svgz",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 603,
        "name": "opacity-increase",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 604,
        "name": "24_dark_format-justify-right",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 605,
        "name": "24_light_format-indent-more",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 606,
        "name": "document-edit",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 607,
        "name": "krita_tool_line",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 608,
        "name": "distribute-horizontal",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 609,
        "name": "stroke-join-miter",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 610,
        "name": "kde",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 611,
        "name": "24_dark_format-text-subscript",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 612,
        "name": "krita_tool_multihand",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 613,
        "name": "krita_tool_measure",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 614,
        "name": "preferences-desktop-display",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 615,
        "name": "transform_icons_liquify_main",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 616,
        "name": "showMarks",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 617,
        "name": "32_light_application-x-krita",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 618,
        "name": "opacity-decrease",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 619,
        "name": "keyframe-remove",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 620,
        "name": "view-fullscreen",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 621,
        "name": "22_dark_draw-arrow-back",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 622,
        "name": "16_dark_format-text-underline",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 623,
        "name": "ratio",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 624,
        "name": "audio-none",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 625,
        "name": "duplicatelayer",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 626,
        "name": "24_dark_zoom-fit-best",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 627,
        "name": "groupLayer",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 628,
        "name": "edit-redo",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 629,
        "name": "shapebrush",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 630,
        "name": "artistic_text",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 631,
        "name": "edit-paste",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 632,
        "name": "tool_zoom",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 633,
        "name": "16_light_format-text-underline",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 634,
        "name": "auto-key-on",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 635,
        "name": "24_light_zoom-fit-best",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 636,
        "name": "24_light_edit-rename",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 637,
        "name": "firstframe",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 638,
        "name": "krita_tool_assistant",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 639,
        "name": "22_dark_draw-freehand",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 640,
        "name": "arrow-left",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 641,
        "name": "16_light_application-x-krita",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 642,
        "name": "paintop_settings_01",
        "format": "svgz",
        "isThemed": false,
        "theme": "none"
    },
    {
        "id": 643,
        "name": "tool_perspectivegrid",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 644,
        "name": "24_dark_draw-arrow-back",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 645,
        "name": "auto-key-off",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 646,
        "name": "configure",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 647,
        "name": "application-exit",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 648,
        "name": "process-stop",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 649,
        "name": "brushsize-decrease",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 650,
        "name": "layer-locked",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 651,
        "name": "krita",
        "format": "png",
        "isThemed": false,
        "theme": "none"
    },
    {
        "id": 652,
        "name": "mirror-view",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 653,
        "name": "transform_icons_liquify_rotateCCW",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 654,
        "name": "32_light_application-x-wmf",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 655,
        "name": "16_light_format-justify-center",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 656,
        "name": "selectionMask",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 657,
        "name": "updateColorize",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 658,
        "name": "symmetry-horizontal",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 659,
        "name": "22_dark_object-align-vertical-top-calligra",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 660,
        "name": "22_light_format-text-bold",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 661,
        "name": "zoom-original",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 662,
        "name": "landscape",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 663,
        "name": "krita_tool_ellipse",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 664,
        "name": "curve-preset-arch",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 665,
        "name": "gamut-mask-on",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 666,
        "name": "16_light_format-justify-left",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 667,
        "name": "24_dark_format-text-color",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 668,
        "name": "deformbrush",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 669,
        "name": "deletelayer",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 670,
        "name": "tangentnormal",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 671,
        "name": "pathpoint-merge",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 672,
        "name": "22_dark_tab-new",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 673,
        "name": "22_dark_format-justify-fill",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 674,
        "name": "wheel-sectors",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 675,
        "name": "rotate-canvas-left",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 676,
        "name": "pathpoint-curve",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 677,
        "name": "24_dark_trash-empty",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 678,
        "name": "showMarksOff",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 679,
        "name": "opacity-increase",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 680,
        "name": "16_light_snap-extension",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 681,
        "name": "ox16-action-object-ungroup-calligra",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 682,
        "name": "24_dark_draw-arrow-up",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 683,
        "name": "bristlebrush",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 684,
        "name": "transform_icons_mirror_y",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 685,
        "name": "view-filter",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 686,
        "name": "curve-preset-linear-reverse",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 687,
        "name": "media-playback-stop",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 688,
        "name": "deletekeyframe",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 689,
        "name": "24_light_format-indent-less",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 690,
        "name": "tool_elliptical_selection",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 691,
        "name": "document-print",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 692,
        "name": "krita_tool_lazybrush",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 693,
        "name": "move-selection",
        "format": "png",
        "isThemed": false,
        "theme": "none"
    },
    {
        "id": 694,
        "name": "dialog-cancel",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 695,
        "name": "gmic",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 696,
        "name": "layer-visible-off",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 697,
        "name": "edit-clear",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 698,
        "name": "16_light_object-group-calligra",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 699,
        "name": "curvebrush",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 700,
        "name": "arrowupblr",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 701,
        "name": "select-all",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 702,
        "name": "22_dark_trash-empty",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 703,
        "name": "edit-undo",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 704,
        "name": "object-rotate-right",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 705,
        "name": "tools-wizard",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 706,
        "name": "transparency-unlocked",
        "format": "svg",
        "isThemed": false,
        "theme": "none"
    },
    {
        "id": 707,
        "name": "addtofolder",
        "format": "png",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 708,
        "name": "pathpoint-corner",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 709,
        "name": "distribute-vertical-center",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 710,
        "name": "transform_icons_liquify_offset",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 711,
        "name": "view-grid",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 712,
        "name": "applications-system",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 713,
        "name": "16_dark_snap-intersection",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 714,
        "name": "arrow-up",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 715,
        "name": "tool_freehand_cursor",
        "format": "png",
        "isThemed": false,
        "theme": "none"
    },
    {
        "id": 716,
        "name": "arrowupblr",
        "format": "png",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 717,
        "name": "distribute-vertical-bottom",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 718,
        "name": "24_dark_format-text-bold",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 719,
        "name": "edit-redo",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 720,
        "name": "transformMask",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 721,
        "name": "22_dark_split",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 722,
        "name": "22_light_snap-orthogonal",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 723,
        "name": "arrow-right",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 724,
        "name": "sketchbrush",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 725,
        "name": "keyframe-add",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 726,
        "name": "transparency-locked",
        "format": "svg",
        "isThemed": false,
        "theme": "none"
    },
    {
        "id": 727,
        "name": "22_light_tab-close",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 728,
        "name": "selectionMask",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 729,
        "name": "paintop_settings_02",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 730,
        "name": "dynabrush",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 731,
        "name": "22_dark_align-horizontal-center",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 732,
        "name": "properties",
        "format": "png",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 733,
        "name": "24_light_format-stroke-color",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 734,
        "name": "paintop_presets_disabled",
        "format": "svgz",
        "isThemed": false,
        "theme": "none"
    },
    {
        "id": 735,
        "name": "groupClosed",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 736,
        "name": "curve-preset-l",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 737,
        "name": "object-rotate-left",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 738,
        "name": "preferences-desktop-display",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 739,
        "name": "22_light_align-vertical-bottom",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 740,
        "name": "landscape",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 741,
        "name": "curve-preset-s",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 742,
        "name": "ox16-action-object-order-front-calligra",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 743,
        "name": "ox16-action-object-align-vertical-bottom-calligra",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 744,
        "name": "wheel-sectors",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 745,
        "name": "split-layer",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 746,
        "name": "transform_icons_main",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 747,
        "name": "16_light_edit-select-all",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 748,
        "name": "trim-to-image",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 749,
        "name": "24_dark_format-text-superscript",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 750,
        "name": "pathpoint-symmetric",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 751,
        "name": "48_dark_klipper",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 752,
        "name": "24_dark_edit-rename",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 753,
        "name": "ox16-action-object-ungroup-calligra",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 754,
        "name": "symmetry-horizontal",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 755,
        "name": "pathpoint-line",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 756,
        "name": "22_dark_snap-orthogonal",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 757,
        "name": "groupOpened",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 758,
        "name": "arrowdown",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 759,
        "name": "showColoringOff",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 760,
        "name": "drive-harddisk",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 761,
        "name": "16_dark_format-text-superscript",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 762,
        "name": "16_light_tab-new",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 763,
        "name": "folder",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 764,
        "name": "unlocked",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 765,
        "name": "16_dark_format-stroke-color",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 766,
        "name": "24_dark_format-indent-more",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 767,
        "name": "duplicatelayer",
        "format": "png",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 768,
        "name": "krita_tool_color_picker",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 769,
        "name": "22_light_shape-choose",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 770,
        "name": "krita_tool_ellipse",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 771,
        "name": "stroke-cap-round",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 772,
        "name": "22_light_snap-extension",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 773,
        "name": "firstframe",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 774,
        "name": "16_dark_format-list-ordered",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 775,
        "name": "16_dark_media-floppy",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 776,
        "name": "rotate-canvas-left",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 777,
        "name": "shapebrush",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 778,
        "name": "filterLayer",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 779,
        "name": "ox16-action-object-align-vertical-bottom-calligra",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 780,
        "name": "24_dark_character-set",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 781,
        "name": "24_light_format-justify-center",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 782,
        "name": "fileLayer",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 783,
        "name": "pathpoint-line",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 784,
        "name": "droppedframes",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 785,
        "name": "tool_polygonal_selection",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 786,
        "name": "24_light_format-list-ordered",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 787,
        "name": "properties",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 788,
        "name": "groupClosed",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 789,
        "name": "16_dark_format-text-direction-rtl",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 790,
        "name": "delete",
        "format": "svgz",
        "isThemed": false,
        "theme": "none"
    },
    {
        "id": 791,
        "name": "krita_tool_reference_images",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 792,
        "name": "im-user",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 793,
        "name": "arrow-down",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 794,
        "name": "22_dark_draw-arrow-up",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 795,
        "name": "polyline",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 796,
        "name": "deletekeyframe",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 797,
        "name": "select-clear",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 798,
        "name": "24_dark_transform-move",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 799,
        "name": "document-open",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 800,
        "name": "22_light_format-justify-center",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 801,
        "name": "view-fullscreen",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 802,
        "name": "preferences-desktop-color",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 803,
        "name": "color-to-alpha",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 804,
        "name": "16_dark_draw-arrow-forward",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 805,
        "name": "krita_tool_color_fill",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 806,
        "name": "16_dark_insert-text",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 807,
        "name": "transform_icons_liquify_offset",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 808,
        "name": "snapshot-load",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 809,
        "name": "interpolation_sharp",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 810,
        "name": "arrow-downleft",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 811,
        "name": "krita_tool_gradient",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 812,
        "name": "22_light_trash-empty",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 813,
        "name": "22_dark_list-remove",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 814,
        "name": "wheel-rings",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 815,
        "name": "64_light_application-x-krita",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 816,
        "name": "arrowupblr",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 817,
        "name": "22_light_media-floppy",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 818,
        "name": "fillLayer",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 819,
        "name": "transform_icons_mirror_x",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 820,
        "name": "paintop_settings_01",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 821,
        "name": "distribute-horizontal",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 822,
        "name": "layer-visible-off",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 823,
        "name": "16_dark_object-group-calligra",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 824,
        "name": "krita_draw_path",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 825,
        "name": "22_light_format-text-color",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 826,
        "name": "download",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 827,
        "name": "brushsize-decrease",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 828,
        "name": "prevframe",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 829,
        "name": "clonebrush",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 830,
        "name": "fillLayer",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 831,
        "name": "zoom-out",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 832,
        "name": "24_light_format-text-color",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 833,
        "name": "cloneLayer",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 834,
        "name": "workspace-chooser",
        "format": "svg",
        "isThemed": false,
        "theme": "none"
    },
    {
        "id": 835,
        "name": "keyframe-remove",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 836,
        "name": "selection-mode_invisible",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 837,
        "name": "warning",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 838,
        "name": "64_light_application-x-wmf",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 839,
        "name": "transform_icons_rotate_cw",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 840,
        "name": "portrait",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 841,
        "name": "16_light_object-align-horizontal-left-calligra",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 842,
        "name": "16_light_format-text-subscript",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 843,
        "name": "distribute-vertical",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 844,
        "name": "22_dark_format-text-direction-rtl",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 845,
        "name": "16_light_snap-intersection",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 846,
        "name": "zoom-out",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 847,
        "name": "16_light_insert-text",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 848,
        "name": "16_light_snap-node",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 849,
        "name": "transparencyMask",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 850,
        "name": "16_light_draw-arrow-back",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 851,
        "name": "krita_tool_grid",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 852,
        "name": "edit-cut",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 853,
        "name": "window-new",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 854,
        "name": "tool_magnetic_selection",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 855,
        "name": "document-import",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 856,
        "name": "tool_outline_selection",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 857,
        "name": "16_light_draw-arrow-down",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 858,
        "name": "vectorLayer",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 859,
        "name": "arrow-topleft",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 860,
        "name": "16_dark_draw-arrow-up",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 861,
        "name": "selection-mode_ants",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 862,
        "name": "24_dark_split",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 863,
        "name": "tangentnormal",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 864,
        "name": "16_light_format-list-ordered",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 865,
        "name": "transform_icons_rotate_ccw",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 866,
        "name": "curve-preset-linear-reverse",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 867,
        "name": "transform_icons_main",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 868,
        "name": "transform_icons_liquify_rotate",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 869,
        "name": "colorsmudge",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 870,
        "name": "dialog-ok",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 871,
        "name": "rotate-canvas-right",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 872,
        "name": "24_dark_draw-arrow-down",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 873,
        "name": "24_light_snap-node",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 874,
        "name": "16_dark_draw-freehand",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 875,
        "name": "zoom-fit",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 876,
        "name": "tool_similar_selection",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 877,
        "name": "calligraphy",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 878,
        "name": "22_light_edit-select-all",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 879,
        "name": "24_dark_format-text-underline",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 880,
        "name": "tool_zoom",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 881,
        "name": "22_dark_snap-extension",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 882,
        "name": "pathpoint-join",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 883,
        "name": "docker_lock_a",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 884,
        "name": "arrowdown",
        "format": "png",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 885,
        "name": "document-export",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 886,
        "name": "addlayer",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 887,
        "name": "transform_icons_cage",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 888,
        "name": "path-break-point",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 889,
        "name": "prevkeyframe",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 890,
        "name": "spraybrush",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 891,
        "name": "24_light_align-vertical-center",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 892,
        "name": "zoom-in",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 893,
        "name": "edit-undo",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 894,
        "name": "krita_tool_line",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 895,
        "name": "16_dark_draw-arrow-back",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 896,
        "name": "stroke-join-bevel",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 897,
        "name": "document-export",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 898,
        "name": "interpolation_bezier",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 899,
        "name": "22_light_character-set",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 900,
        "name": "path-break-point",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 901,
        "name": "16_dark_align-vertical-center",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 902,
        "name": "addduplicateframe",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 903,
        "name": "16_light_format-text-direction-rtl",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 904,
        "name": "22_light_object-align-horizontal-left-calligra",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 905,
        "name": "select",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 906,
        "name": "stroke-cap-butt",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 907,
        "name": "16_dark_format-text-italic",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 908,
        "name": "24_dark_draw-freehand",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 909,
        "name": "24_light_draw-arrow-up",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 910,
        "name": "ox16-action-object-align-vertical-center-calligra",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 911,
        "name": "select",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 912,
        "name": "16_light_tab-close",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 913,
        "name": "krita_tool_lazybrush",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 914,
        "name": "paintop_settings_01",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 915,
        "name": "transparency-disabled",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 916,
        "name": "edit-copy",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 917,
        "name": "addlayer",
        "format": "png",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 918,
        "name": "removefromfolder",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 919,
        "name": "24_dark_format-indent-less",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 920,
        "name": "document-print",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 921,
        "name": "system-help",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 922,
        "name": "passthrough-disabled",
        "format": "svgz",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 923,
        "name": "16_dark_align-horizontal-left",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 924,
        "name": "addcolor",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 925,
        "name": "16_dark_zoom-fit-best",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 926,
        "name": "22_light_draw-freehand",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 927,
        "name": "16_dark_format-text-strikethrough",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 928,
        "name": "view-preview",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 929,
        "name": "22_dark_align-vertical-bottom",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 930,
        "name": "distribute-vertical-bottom",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 931,
        "name": "arrow-topright",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 932,
        "name": "16_dark_edit-rename",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 933,
        "name": "24_dark_snap-node",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 934,
        "name": "im-user",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 935,
        "name": "wheel-light",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 936,
        "name": "krita_tool_move",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 937,
        "name": "updateColorize",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 938,
        "name": "22_light_align-vertical-center",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 939,
        "name": "pathsegment-curve",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 940,
        "name": "22_light_transform-move",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 941,
        "name": "22_light_align-horizontal-right",
        "format": "svg",
        "isThemed": false,
        "theme": "light"
    },
    {
        "id": 942,
        "name": "pathpoint-curve",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 943,
        "name": "audio-none",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 944,
        "name": "droppedframes",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 945,
        "name": "stroke-join-round",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 946,
        "name": "16_dark_edit-select-all",
        "format": "svg",
        "isThemed": false,
        "theme": "dark"
    },
    {
        "id": 947,
        "name": "selection-mode_mask",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 948,
        "name": "lastframe",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 949,
        "name": "infinity",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 950,
        "name": "transparency-enabled",
        "format": "svg",
        "isThemed": true,
        "theme": "light"
    },
    {
        "id": 951,
        "name": "particlebrush",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    },
    {
        "id": 952,
        "name": "addcolor",
        "format": "svg",
        "isThemed": true,
        "theme": "dark"
    }
]


export default IconDictionaryData;

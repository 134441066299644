import React from "react";
import PrismCode from '../PrismCode'

import {Link} from "react-router-dom";

const codeBlockOne = `
from PyQt5.QtWidgets import QDialog, QLabel, QVBoxLayout

# add label to test notifier
newLayout = QVBoxLayout()
newLabel = QLabel('new label')
newLayout.addWidget(newLabel)


# hook up notifier object to watch fon configuration  changes
# https://api.kde.org/krita/html/classNotifier.html
appNotifier  = Krita.instance().notifier()
appNotifier.setActive(True)

# create dialog  and show it
newDialog = QDialog() 
newDialog.setLayout(newLayout)
newDialog.show() # show the dialog


def configChangedDoStuff():
    newLabel.setText('configuration changed')
    
    # Note: this shows in linux terminal, but not output in Scripter
    print('config changed!!')


appNotifier.configurationChanged.connect(configChangedDoStuff)
`;




const codeBlockTwo = `
from PyQt5.QtWidgets import QDialog, QLabel, QVBoxLayout
from PyQt5.QtCore import QObject, pyqtSlot
from krita import  *

# add label to test notifier
newLayout = QVBoxLayout()
newLabel = QLabel('new label')
newLayout.addWidget(newLabel)

# hook up notifier object to watch for configuration changes
# https://api.kde.org/krita/html/classNotifier.html
appNotifier  = Krita.instance().notifier()
appNotifier.setActive(True)

# create dialog  and show it
newDialog = QDialog() 
newDialog.setLayout(newLayout)
newDialog.show() # show the dialog

def viewClosedEvent(closedView):
    newLabel.setText('view closed')
    print(closedView);

def viewOpenedEvent(openedView):
    newLabel.setText('view opened')
    print(openedView);

appNotifier.viewClosed.connect(viewClosedEvent)
appNotifier.viewCreated.connect(viewOpenedEvent)
`;

const codeWindowSignals = `
from PyQt5.QtWidgets import QDialog, QLabel, QVBoxLayout
from krita import *

def updateThings():
    newLabel.setText('theme changed')

mainWindow = Krita.instance().activeWindow()
mainWindow.themeChanged.connect(updateThings)


# add label to test notifier
newLayout = QVBoxLayout()
newLabel = QLabel('new label')
newLayout.addWidget(newLabel)


# create dialog  and show it
newDialog = QDialog() 
newDialog.setWindowTitle('Testing new connection')
newDialog.setLayout(newLayout)
newDialog.show() # show the dialog

`;



class LessonNotifiers extends React.Component {

  // note: to use the code, you have to wrap it in   {`code goes here` } so Reeact can render it right
  // this is called ES5 template strings
  render() {
    return (
      <div>
        <h2>Watching for application changes</h2>

        <p>When your script is running, there might be certain things you only want to do when a new image is created. Other things you might want to apply only when Krita changes its configuration. Waiting for these special events from Krita is known as Notifiers. Let's do an example of listening for changes in the configuration.</p>

       <PrismCode code={codeBlockOne} language="python" />

       <p>We wrote out a little more code than we probably need, but it is there to explain how the code works. If you run this code in scripter, you will see a dialog appear and a label that says "new label". Open up Krita's configuration window, make a change, then press ok to get out of it. You will see the label's text change. You will not see the print statement in the output though like we have seen in the past. The notifier did work, though, since we saw the label change at the right time. </p> 
         
      <p>Under the hood Notifiers are just signals, which we have already gone over previously. They are just special "Krita signals" that are tied to events in the application. When we put the above code in a dialog, the script keeps listening and running. If you run code that does not have a user interface, or finishes quickly, there is a good change these notifiers will not be picked up. This is an important thing to note when you use notifiers.</p>

      <p>You must also make the notifier active before it will send signals. By default notifiers are not active, so nothing will happen if you try to make a connection without setting it active first.</p>

      <h3>Monitoring when views open and close</h3>

      <p>Here is another code snippet with how we can know when new views are added or removed. If you aren't sure what a view is, make sure to check out the Documents lesson for clarification. These notifications can be good for determining if tools should be active, or if any other cleaning up needs to be done.</p>


      <PrismCode code={codeBlockTwo} language="python" />

      <p>I added connections for both adding and removing views. When you open the dialog and add and remove documents, the label text will update. These notifier slots have parameters that come with it. When a view is created, it also sends you the new view itself.  </p>



      <h3>Other classes that have notifier like behavior</h3>

      <p>While the Notifier object has some signals that you can listen to, there are signals in other areas as well that can be easy to miss. For example if you want to listen for when the theme changes, you do that from the window object like this.</p>

      <PrismCode code={codeWindowSignals} language="python" />

      <p>To see a full list of what the Notifier class can do, check out <a href="https://api.kde.org/krita/html/classNotifier.html" target="_blank" rel="noopener noreferrer" >the documentation</a> . If you move around that documentation site a bit, look for other events that might be useful in the Document or Window classes. The events are placed in areas that relate to the classes. This is why the different events are in different locations.</p>

      </div>
    );
  }

}

export default LessonNotifiers;



import React from "react";
import {
  Route,
  NavLink,
  Redirect
} from "react-router-dom";
import LessonData from './lessons/DataForLessons' // help map ID to component

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

// https://react-icons.github.io/react-icons/icons?name=ai
// import icons individually. 
import {AiOutlineDown } from "react-icons/ai"; 



class Lessons extends React.Component  {

  constructor() {
    super();

    this.state = {
      isMobile: false, // variable not used
      mainTopicsExpanded: true,
      advancedTopicsExpanded: true,
      referencesExpanded: true
    };
  }
  
  componentDidMount() {
    // this isn't even used, but how you could add some javascript to dynamically change CSS
    window.addEventListener('resize', () => {
        this.setState({
            isMobile: window.innerWidth < 1200
        });
    }, false);
}



  render() {
    const isMobile = this.state.isMobile ? 'mobile' : '';

    /* Toggles state if accordian menus should show or not */ 
    const toggleShowMainConcepts = () => (event, newExpanded) => {
      this.setState( { mainTopicsExpanded: !this.state.mainTopicsExpanded  } );
    };
    const toggleShowAdvancedTopics = () => (event, newExpanded) => {
      this.setState( { advancedTopicsExpanded: !this.state.advancedTopicsExpanded  } );
    };

    const toggleShowReferences = () => (event, newExpanded) => {
      this.setState( { referencesExpanded: !this.state.referencesExpanded  } );
    };



    let lessonBreadcrumLookup = (urlPath) => {
      
      // pluck the last part of the URL. this is the ID of the lesson
      let lessonId = urlPath.split("/");
      lessonId = lessonId[lessonId.length-1];

      const lessonIndex = LessonData.findIndex(x => x.id === lessonId);


      // in case we hit the /lessons before the redirect, just return empty to avoid an error
      if (LessonData[lessonIndex] === undefined) return '';


      // since we have categories, we need to next figure out what index it is at for its category
      let lessonsInCategory = LessonData.filter(x => x.level === LessonData[lessonIndex].level );
      const categoryLessonIndex = lessonsInCategory.findIndex(x => x.id === lessonId);

      const outputString = LessonData[lessonIndex].level + ' lesson ' + (categoryLessonIndex + 1).toString(); 

      return outputString; 
    }

    let lessonLookup = (urlPath) => {
      // pluck the last part of the URL. this is the ID of the lesson
      let lessonId = urlPath.split("/");
      lessonId = lessonId[lessonId.length-1];

      const lessonIndex = LessonData.findIndex(x => x.id === lessonId);

      // in case we hit the /lessons before the redirect, just return empty to avoid an error
      if (LessonData[lessonIndex] === undefined) return '';

      return LessonData[lessonIndex];
    }



    return (
      <div>

        <div className="lessons-page">
          <div className={ `lessons-item-container ` + isMobile} >

            { /*It doesn't matter what lessonId is called, it seems to send the key 
              Any links that are made in this function will follow these router rules  */  }
              <div className="lesson-breadcrumb">{lessonBreadcrumLookup(this.props.location.pathname)}</div>
              <h1>{ lessonLookup(this.props.location.pathname).name }</h1>
              <p className="lesson-overview">{ lessonLookup(this.props.location.pathname).overview }</p>

              <Route path={`${this.props.match.url}/`} exact >
                <Redirect to={`${this.props.match.url}/introduction`} />
              </Route>
              <Route path={`${this.props.match.url}/:lessonId`} component={Lesson}/>

              <div className="help-container">
                <h3>Have questions, need help, or find a typo?</h3>
                <p>Head to the Krita artists where we have a dedicated <a href="https://krita-artists.org/c/develop/plugins-development/16" target="_blank" rel="noopener noreferrer">area for plugin development</a> and give any feedback you might have. </p>
              </div>


          </div>

          <div className="lessons-listing-container">

            {/* MAIN CONCEPTS ACCORDIAN MENU */ }
           <Accordion  expanded={this.state.mainTopicsExpanded === true} onChange={toggleShowMainConcepts()} >
            <AccordionSummary
              expandIcon={<AiOutlineDown />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className="lessons-title">MAIN CONCEPTS</div>
            </AccordionSummary>
            <AccordionDetails>
                
              <ol>
              {LessonData.filter( data => data.level === 'beginner')
                .map(({ level, id, name }) => (
                <li key={id}>
                  <NavLink to={`${this.props.match.url}/${id}`} activeClassName="active-lesson-nav-link" >{name}</NavLink>
                </li>
              ))}

              </ol>
            </AccordionDetails>
          </Accordion>


          {/* PLUGIN DEVELOPMENT ACCORDIAN MENU*/ }
          <Accordion  expanded={ this.state.advancedTopicsExpanded === true} onChange={toggleShowAdvancedTopics()}>
            <AccordionSummary
              expandIcon={<AiOutlineDown />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
            <div className="lessons-title">PLUGIN DEVELOPMENT</div>
            </AccordionSummary>
            <AccordionDetails>         

              <ol>
              {LessonData.filter( data => data.level === 'intermediate')
                .map(({ level, id, name }) => (
                <li key={id}>
                  <NavLink to={`${this.props.match.url}/${id}`} activeClassName="active-lesson-nav-link" >{name}</NavLink>
                </li>
              ))}            

              </ol>

            </AccordionDetails>
        </Accordion>



          {/* REFERENCE LESSON ACCORDIAN MENU*/ }
          <Accordion  expanded={ this.state.referencesExpanded === true} onChange={toggleShowReferences()}>
            <AccordionSummary
              expandIcon={<AiOutlineDown />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
            <div className="lessons-title">REFERENCES</div>
            </AccordionSummary>
            <AccordionDetails>         

              <ul>
              {LessonData.filter( data => data.level === 'reference')
                .map(({ level, id, name }) => (
                <li key={id}>
                  <NavLink to={`${this.props.match.url}/${id}`} activeClassName="active-lesson-nav-link" >{name}</NavLink>
                </li>
              ))}            

              </ul>

            </AccordionDetails>
        </Accordion>


          </div>      
        </div>

      </div>
    );

  }
}


function Lesson ({match}) {

// find component name for lesson ID
let lessonId = match.params.lessonId;

const lessonIdData = LessonData.find(x => x.id === lessonId);
const ComponentToLoad = lessonIdData.component; // need to have variable with capital case with dynamic loading components

const firstUrlLevel = match.path.split('/')[1]; // send to previous/next buttons to use to construct a new URL
//console.log(firstUrlLevel);

// figure out if we need a back and next button
const currentIndexInLessons = LessonData.findIndex(i => i.id === lessonIdData.id );

  return (
    <div>
      <ComponentToLoad />
      <div className="next-previous-lesson-container">
        <span className="previous"  >
          <PreviousLessonButton  currentLessonIdex={currentIndexInLessons} match={firstUrlLevel} />
        </span>
       
        <span className="next"  >
         <NextLessonButton  currentLessonIdex={currentIndexInLessons} match={firstUrlLevel} />
         </span>
      </div>
    </div>
  )
}



function PreviousLessonButton({currentLessonIdex, match}) {

  if(currentLessonIdex > 0) {
    // enable the back button
    const lesson = LessonData[currentLessonIdex-1];
    return (
      <div>
          <NavLink to={`/${match}/${lesson.id}`} > &lt; Previous Lesson  </NavLink>
        <div><em>{lesson.name}</em></div>
        </div>
    )
  } 
  
  return '';
}

function NextLessonButton({currentLessonIdex, match}) {

  if(currentLessonIdex < LessonData.length-1) {
       // enable next button
      const lesson = LessonData[currentLessonIdex+1];
      return  (
        <div>
            <NavLink to={`/${match}/${lesson.id}`} > Next Lesson &gt; </NavLink>
            <div><em>{lesson.name}</em></div>
        </div>
      )
       
      
    }

  return '';
}


export default Lessons;



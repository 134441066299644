import React from "react";
import PrismCode from '../PrismCode'

// https://react-icons.github.io/react-icons/icons?name=ai
// import icons individually. 
import { AiOutlineBulb } from "react-icons/ai"; 

const codeBlockOne = `
app = Krita.instance() # get the application
documentsOpen = app.documents() # get the open documents
documentCount = len( app.documents() ) # get how many documents opened
print(documentCount)
`;


const codeBlockTwo = `
# create new document createDocument(width, height, name, colorSpace, bitDepth, colorProfile, DPI)
newDocument = Krita.instance().createDocument(1280, 1024, "Document name", "RGBA", "U8", "", 300.0)
Krita.instance().activeWindow().addView(newDocument) # shows it in the application
`;

const codeBlockFour = `
currentDocument = Krita.instance().activeDocument()
currentDocument.crop(0 ,0, 100, 300) # crop(x, y, width, height)
`;

const codeBlockFive = `
# will open up a new dialog to specify name and location
Krita.instance().action('file_save_as').trigger()

# will save over existing document if it exists
Krita.instance().action('file_save').trigger()
`;


const codeBlockSix = `
currentDocument = Krita.instance().activeDocument()
currentDocument.saveAs('/home/scott/Desktop/export-image.kra')
# Windows - would have the location like C:/location-to-file/file.kra
`;


const codeBlockSeven = `
currentDocument = Krita.instance().activeDocument()
currentDocument.setBatchmode(True) # no popups while saving
currentDocument.saveAs('/home/scott/Desktop/export-image.png')
`;


const codeBlockEight = `
from krita import * # you need this in Scripter with InfoObject

currentDocument = Krita.instance().activeDocument()

# setup some export parameters for PNG
exportParameters = InfoObject()
exportParameters.setProperty("alpha", True)
exportParameters.setProperty("compression", 6) # 0-9
exportParameters.setProperty("indexed", False)

# exportImage supports jpg and png
currentDocument.exportImage('/home/scott/Desktop/export-image.png', exportParameters )
`;




class LessonTalkingWithKrita extends React.Component {

  render() {
    return (
      <div>

        <h2>Say "hey Krita" with scripting</h2>

        <p>I imagine you are scripting with Krita because you want it to do something, or do a series of things automatically. To get a reference to Krita and start working use the following script.</p>

        <PrismCode code={codeBlockOne} language="python" />

        <p>If you run this code, you will probably see a “0” in the output. The first line allows us to talk with the Krita application. The second line gets a list of documents that are open. The third line finds how many documents are open. If you create a document and try to run this code again, the output will change.</p>

        <p>This brings up a good point about working with documents. There are a lot of things you can do in Krita. Some things will give errors if you don't have a document open. For example if you try to rotate an image and there was no image open, there is going to be an error.  As we will find out later, the order we do things in matters. If certain code is done before other code, things won't work.</p>

        <h3>Creating a new document</h3>

        <p>Let's create an empty document and start doing a few things with it for some practice. To create a new document write the following code</p>

        <PrismCode code={codeBlockTwo} language="python" />

        <blockquote>
          <p><AiOutlineBulb /> Tip</p>
          <p>If you see a <strong>#</strong> in the code examples, those are just comments. They do not do anything. Code can get very complicated looking. These comments help make reading code easier to understand. It can be tricky to know when to write a comment. One rule of thumb is to think <em>"If I come back to this code in a year, am I going to understand this?". If the answer is no, you might want to write a comment.</em></p>
        </blockquote>


        <p>This creates a new document and adds it to a view. It is important to understand that in Krita there is a difference between a view and a document. A document is some pixel data in memory that has some properties like the ability to rotate it. The view is what shows the document in the application's GUI. To give you an idea of why this separation is important, think of something like the Overview docker. That needs to see what the pixel data is, but it isn't a canvas you can draw on.</p>

        <h3>Modifying existing documents</h3>

        <p>There is a possibility that you just want to modify an existing document...not create a new document and do stuff with it. If you want to do things with an existing canvas you can do it this way.</p>

        <PrismCode code={codeBlockFour} language="python" />

        <p>This will grab your current canvas and perform a crop on it. Just make sure you have a canvas actually open. Try to close all your canvases in Krita and run this script again. You will see an error. This is because there is no activeDocument() to do a crop on.</p>

        <h3>Saving and exporting documents</h3>

        <p>If you want to call a simple action like saving, as, you can just use simple action triggers like this. If the file has not been saved before a dialog will open up asking you where you want to save it, and what the file name should be.</p>

        <PrismCode code={codeBlockFive} language="python" />


        <p>If you want a little more control of the file saving, you can do it through Krita's document API like this</p>

        <PrismCode code={codeBlockSix} language="python" />

        <p>For the example above, you can change out the extension to whatever you want. KRA is the working format for Krita, but you can also use something like PNG or PSD. If you set the format to PNG, you will see a dialog appear before it actually saves. What if you don't want to see that every time you save. You can use a batch mode setting to accomplish this.</p>

        <PrismCode code={codeBlockSeven} language="python" />

        <p>Batch mode can be useful when you want to modify or change a lot of files in one script. You wouldn't want to have to press the ok button for every file. </p>

        <p>If you want control of all the export options while you are exporting, you can use the exportImage() function that will take care of this. This takes a new InfoObject class that contains all the properties.</p>

        <PrismCode code={codeBlockEight} language="python" />

        <h3>More ways to modify the document</h3>
        <p>At this point you might be wondering what are all the different things you can do with the document. If you take a look at the <a href="https://api.kde.org/krita/html/classDocument.html" target="_blank" rel="noopener noreferrer">Document API</a>, you can see a number of things that you can do with a document. There are more things we can also do with actions, which we will learn about in the next section.</p>



      </div>
    );
  }

}

export default LessonTalkingWithKrita;


